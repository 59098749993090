// NavBar.tsx

import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom';
import styles from '../../styles/ navbar/NavBar.module.css';
import NavBarModal from './navbarModal';

type property = {
  id: number;
  buildingName: string;
  ApartmentNumber: string;
  area: string;
  user: {name: string; image:string}
};


const NavBar: React.FC = () => {
  const [property, setProperty] = useState<property | null>(null);
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  useEffect(() => {
    const storedProperty = localStorage.getItem("Property");
    const parsedProperty = storedProperty ? JSON.parse(storedProperty) : null;
    setProperty(parsedProperty);
  }, []);


  const openModal = () => {
    setModalIsOpen(true);
    setShouldAnimateOut(false);
  };

  const closeModal = () => {
    setShouldAnimateOut(true);
  };

  useEffect(() => {
    if (shouldAnimateOut) {
      const timer = setTimeout(() => {
        setModalIsOpen(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [shouldAnimateOut]);


  return (
    <nav>
      <div className='test-container flex'>
        <div className={styles.logoContainer}>
          {property &&
            <div className={styles.logo}>
              <Link to="/app">
                <img
                  style={property?.user.image ? { borderRadius: "8px" } : undefined}
                  src={property?.user.image || "/logo.svg"}
                  alt={property?.user.image ? `${property.user.name || "User"}'s Profile Image` : "Default Logo"}
                  loading="lazy"
                />            
              </Link>
            </div>
          }
        </div>
        <div className={styles.dropdownContainer}>
          <button onClick={openModal} className={styles.dropdownButton}>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
          </button>
        </div>
      </div>
      <NavBarModal shouldAnimateOut={shouldAnimateOut} isOpen={modalIsOpen} onRequestClose={closeModal} />
    </nav>
  );
};

export default NavBar;
