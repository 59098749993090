import { Country } from "../types/services";

export const isValidNumber = (
  phoneNumber: string,
  selectedCountry: Country
) => {
  if (phoneNumber.length !== selectedCountry.format.length) {
    return false;
  }
  return true;
};

export const formatPhoneNumber = (
  selectedCountry: Country,
  value: string
): string => {
  const format = selectedCountry.format.replace(/\./g, "0");
  let formattedNumber = "";
  let numberIndex = 0;

  for (let i = 0; i < format.length; i++) {
    if (numberIndex >= value.length) break;

    if (format[i] === "0") {
      formattedNumber += value[numberIndex];
      numberIndex++;
    } else {
      formattedNumber += format[i];
    }
  }
  return formattedNumber;
};

export const checkSavedPhoneNumber = () => {
  const phoneNumber: string | null = localStorage.getItem("phoneNumber");

  const parsePhoneNumber = (phoneNumber: string | null) => {
    if (!phoneNumber) return null;

    try {
      return JSON.parse(phoneNumber);
    } catch (error) {
      localStorage.removeItem("phoneNumber");
    }
  };

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

  if (
    parsedPhoneNumber &&
    (!parsedPhoneNumber.number || !parsedPhoneNumber.country)
  )
    localStorage.removeItem("phoneNumber");
};
