import React from "react";
import styles from "../styles/notfound.module.css";

const PropertyNotFound: React.FC = () => {
  return (
    <div className={`${styles.section}`}>
        <img src="/50.svg" alt="" />
        <div className={styles.page}>
            <p>Try scan the QR code again</p>
        </div>
    </div>
  );
};

export default PropertyNotFound;
