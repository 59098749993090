import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styles from "../../styles/orders.module.css";
import { SelectedOrder } from "../../utils/types/services";

import { ReactComponent as ReactTerribleIcon } from "../../assets/rating/nonColored/terrible.svg";
import { ReactComponent as ReactColoredTerribleIcon } from "../../assets/rating/colored/terrible.svg";

import { ReactComponent as ReactBadIcon } from "../../assets/rating/nonColored/bad.svg";
import { ReactComponent as ReactColoredBadIcon } from "../../assets/rating/colored/bad.svg";

import { ReactComponent as ReactMehIcon } from "../../assets/rating/nonColored/meh.svg";
import { ReactComponent as ReactColoredMehIcon } from "../../assets/rating/colored/meh.svg";

import { ReactComponent as ReactGoodIcon } from "../../assets/rating/nonColored/good.svg";
import { ReactComponent as ReactColoredGoodIcon } from "../../assets/rating/colored/good.svg";

import { ReactComponent as ReactGreatIcon } from "../../assets/rating/nonColored/great.svg";
import { ReactComponent as ReactColoredGreatIcon } from "../../assets/rating/colored/great.svg";
import axios from "axios";
import notify from "../../components/Errortoast";

interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  shouldAnimateOut: boolean;
  selectedOrder: SelectedOrder;
}

const RatingModal: React.FC<CustomModalProps> = ({
  selectedOrder,
  isOpen,
  onRequestClose,
  shouldAnimateOut,
}) => {
  const [modalSelectedOrder, setModalSelectedOrder] =
    useState<SelectedOrder>(selectedOrder);
  const [comment, setComment] = useState("");
  const token = localStorage.getItem("PropertyToken");

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }
    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [isOpen]);

  const handleIconClick = (rating: number) => {
    setModalSelectedOrder({
      orderId: selectedOrder.orderId,
      rating,
      service: selectedOrder.service,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!token) {
      return;
    }

    if (!modalSelectedOrder.rating) {
      notify({ type: 'error', content: 'Please give rate!' });
      return;
    }

    const trimmedComment = comment.trim();

    const ratingData: any = {
      service: modalSelectedOrder.service,
      orderId: modalSelectedOrder.orderId,
      rating: modalSelectedOrder.rating,
    };

    if (trimmedComment) {
      ratingData.comment = trimmedComment;
    }

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}services/rate`,
         ratingData,
         {
          headers: {
            propertyToken: token,
          },
        }
        );
      notify({ type: 'success', content: 'Thank you! Your feedback means a lot' });
    } catch (error: any) {
      console.error(
        "Error submitting rating:",
        error.response ? error.response.data : error.message
      );
      notify({ type: 'error', content: "Failed to submit rating" });
    }finally{
      onRequestClose()
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      className={`${styles.rateModal} ${shouldAnimateOut ? styles.exit : ""}`}
      overlayClassName={`${shouldAnimateOut ? styles.exitOverlay : ""}`}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <div className={`flex flex-col ${styles.rating}`}>
        <h4>How was your experience?</h4>
        <div className={`flex ${styles.faces}`}>
          <span onClick={() => handleIconClick(1)}>
            {modalSelectedOrder.rating === 1 ? (
              <ReactColoredTerribleIcon />
            ) : (
              <ReactTerribleIcon />
            )}
            <p className={modalSelectedOrder.rating === 1 ? styles.rateSelected : ""}>Terrible</p>
          </span>
          <span onClick={() => handleIconClick(2)}>
            {modalSelectedOrder.rating === 2 ? (
              <ReactColoredBadIcon />
            ) : (
              <ReactBadIcon />
            )}
            <p className={modalSelectedOrder.rating === 2 ? styles.rateSelected : ""}>Bad</p>
          </span>
          <span onClick={() => handleIconClick(3)}>
            {modalSelectedOrder.rating === 3 ? (
              <ReactColoredMehIcon />
            ) : (
              <ReactMehIcon />
            )}
            <p className={modalSelectedOrder.rating === 3 ? styles.rateSelected : ""}>Meh</p>
          </span>
          <span onClick={() => handleIconClick(4)}>
            {modalSelectedOrder.rating === 4 ? (
              <ReactColoredGoodIcon />
            ) : (
              <ReactGoodIcon />
            )}
            <p className={modalSelectedOrder.rating === 4 ? styles.rateSelected : ""}>Good</p>
          </span>
          <span onClick={() => handleIconClick(5)}>
            {modalSelectedOrder.rating === 5 ? (
              <ReactColoredGreatIcon />
            ) : (
              <ReactGreatIcon />
            )}
            <p className={modalSelectedOrder.rating === 5 ? styles.rateSelected : ""}>Great</p>
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h4>How can we improve?</h4>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className={styles.comment}
          placeholder="Anything to add?..."
          maxLength={200}
        ></textarea>
      </div>
      <button onClick={(e) => handleSubmit(e)} className={styles.submit}>
        Submit review
      </button>
    </Modal>
  );
};

export default RatingModal;
