import React, { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "../../styles/switchTransition.css";
import { Helmet } from "react-helmet";
import SelectCategory from "../../components/groceryService/selectCategory";
import Basket from "../../components/groceryService/basket";
import { useNavigate } from "react-router-dom";
import GroceryPayment from "../../components/groceryService/payment";

type Item = {
  id: string;
  price: number;
  name: string;
  image: object;
  quantity: number;
  groups: any;
};

type GroceryProcessStep = "basket" | "payment";

const GroceryCheckout: React.FC = () => {
  const [currentComponent, setCurrentComponent] =
    useState<GroceryProcessStep>("basket");
  const [allowSubstitution, setAllowSubstitution] = useState<boolean>(true);
  const [isForward, setIsForward] = useState<boolean>(true);
  const [themeColor, setThemeColor] = useState('#ffffff'); 
  const navigate = useNavigate();

  useEffect(() => {
    const primaryHeaderColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-header-color');
    setThemeColor(primaryHeaderColor.trim());
  }, []);

  const handlePayment = (allowSubstitution: boolean) => {
    setIsForward(true);
    setTimeout(() => {
      setCurrentComponent("payment");
      setAllowSubstitution(allowSubstitution);
    }, 100);
  };

  const handleBack = () => {
    setIsForward(false);
    navigate("/app/groceries/categories")
  };


  const handlePaymentBack = () => {
    setIsForward(false);

    setTimeout(() => {
      setCurrentComponent("basket");
    }, 100);

  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={currentComponent}
        timeout={200}
        classNames={isForward ? "fade-forward" : "fade-backward"}
      >
        <div className="transition-wrapper">
          {currentComponent === "basket" && (
            <>
              <Helmet>
                <meta name="theme-color" content="#fff"/>
              </Helmet>
              <Basket
                onContinue={handlePayment}
                onBack={() => handleBack()}
              />
            </>
          )}
          {currentComponent === "payment" && (
            <>
              <Helmet>
                <meta name="theme-color" content="#fff" />
              </Helmet>
              <GroceryPayment
                allowSubstitution={allowSubstitution}
                onBack={() => handlePaymentBack()}
              />
            </>
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default GroceryCheckout;
