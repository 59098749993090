import React, { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import FoodSelectItem from "../../components/foodService/selectItem";
import FoodPayment from "../../components/foodService/payment";
import Basket from "../../components/foodService/basket";
import "../../styles/switchTransition.css";
import { Helmet } from "react-helmet";

type Item = {
  id: string;
  price: number;
  name: string;
  image: object;
  quantity: number;
  groups: any;
};

type FoodProcessStep = "selectItem" | "basket" | "payment";

const FoodCheckout: React.FC = () => {
  const [currentComponent, setCurrentComponent] =
    useState<FoodProcessStep>("selectItem");
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const [isForward, setIsForward] = useState<boolean>(true);
  const [themeColor, setThemeColor] = useState('#ffffff'); 

  useEffect(() => {
    const primaryHeaderColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-header-color');
    setThemeColor(primaryHeaderColor.trim());
  }, []);

  const handleBasket = () => {
    setIsForward(true);
    setTimeout(() => {
      setCurrentComponent("basket");
    }, 100);
  };

  const handlePayment = (items: Item[], additionalNotes: string) => {
    setIsForward(true);
    setTimeout(() => {
      setCurrentComponent("payment");
      setAdditionalNotes(additionalNotes);
    }, 100);
  };

  const handleBack = (previousStep: FoodProcessStep) => {
    setIsForward(false);

    setTimeout(() => {
      setCurrentComponent(previousStep);
    }, 100);
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={currentComponent}
        timeout={200}
        classNames={isForward ? "fade-forward" : "fade-backward"}
      >
        <div className="transition-wrapper">
          {currentComponent === "selectItem" && (
            <>
              <Helmet>
                <meta name="theme-color" content={themeColor} />
              </Helmet>
              <FoodSelectItem onContinue={handleBasket} />
            </>
          )}
          {currentComponent === "basket" && (
            <>
              <Helmet>
                <meta name="theme-color" content="#fff"/>
              </Helmet>
              <Basket
                onContinue={handlePayment}
                onBack={() => handleBack("selectItem")}
              />
            </>
          )}
          {currentComponent === "payment" && (
            <>
              <Helmet>
                <meta name="theme-color" content="#fff" />
              </Helmet>
              <FoodPayment
                additionalNotes={additionalNotes}
                onBack={() => handleBack("basket")}
              />
            </>
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default FoodCheckout;
