// src/components/CustomModal.tsx

import React from 'react';
import styles from '../styles/cancelationModal.module.css'
import Modal from 'react-modal';
import notify from './Errortoast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const CancelationModal: React.FC<any> = ({ isOpen, onRequestClose, service, id}) => {
    const navigate = useNavigate()

    const cancelOrder = async ()=>{
        try {
            const token = localStorage.getItem("PropertyToken");
            if (!token) {
                throw new Error("PropertyToken not found");
            }
            
            const response = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}services/${service}/orders/${id}`,{},
                {
                    headers: {
                        propertyToken: token,
                    },
                }
            );
            notify({ type: 'success', content: 'Order has been canceled' });
            navigate('/app/orders')
        } catch (error) {
            notify({ type: 'error', content: 'Failed to cancel order!' });
            console.error("Failed to cancel order!", error);
        }
    }

  return (
    <Modal  isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Example Modal"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                content: {
                    width: '100%',
                    height: 'auto',
                    margin: '0',
                    padding: '24px 16px 16px 16px',
                    gap: '24px',
                    borderRadius: '8px',
                    top: 'auto',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    maxWidth: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
      }}>
        <div className={`flex flex-col justify-center items-center ${styles.container}`}>
            <span>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 14.625H39" stroke="#323E48" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.25 22.125V33.375" stroke="#323E48" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M27.75 22.125V33.375" stroke="#323E48" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.875 14.625L12.75 37.125C12.75 38.1196 13.1451 39.0734 13.8483 39.7766C14.5516 40.4799 15.5054 40.875 16.5 40.875H31.5C32.4946 40.875 33.4484 40.4799 34.1516 39.7766C34.8549 39.0734 35.25 38.1196 35.25 37.125L37.125 14.625" stroke="#323E48" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M18.375 14.625V9C18.375 8.50272 18.5725 8.02581 18.9242 7.67417C19.2758 7.32254 19.7527 7.125 20.25 7.125H27.75C28.2473 7.125 28.7242 7.32254 29.0758 7.67417C29.4275 8.02581 29.625 8.50272 29.625 9V14.625" stroke="#323E48" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
            </span>
            <h4>Are you sure you want to cancel this service?</h4>
            <p>This action cannot be undone. If you wish to bring it back,
                you’ll have to create a new post again.</p>
            <div className={styles.actions}>
                <button onClick={cancelOrder}>Cancel service</button>
                <button onClick={onRequestClose}>Go back</button>
            </div>
       </div>
    </Modal>
  );
};

export default CancelationModal;
