import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "../../styles/orders.module.css";
import { Order, SelectedOrder, Service } from "../../utils/types/services";
import {
  convertDateBackOnly,
  convertDateTimeBack,
} from "../../utils/times/timeDateConvert";
import EmptyOrders from "../../components/emptyOrders";
import { imgTwoTexts } from "../../utils/skeleton";

import { ReactComponent as ReactTerribleIcon } from "../../assets/rating/nonColored/terrible.svg";
import { ReactComponent as ReactColoredTerribleIcon } from "../../assets/rating/colored/terrible.svg";

import { ReactComponent as ReactBadIcon } from "../../assets/rating/nonColored/bad.svg";
import { ReactComponent as ReactColoredBadIcon } from "../../assets/rating/colored/bad.svg";

import { ReactComponent as ReactMehIcon } from "../../assets/rating/nonColored/meh.svg";
import { ReactComponent as ReactColoredMehIcon } from "../../assets/rating/colored/meh.svg";

import { ReactComponent as ReactGoodIcon } from "../../assets/rating/nonColored/good.svg";
import { ReactComponent as ReactColoredGoodIcon } from "../../assets/rating/colored/good.svg";

import { ReactComponent as ReactGreatIcon } from "../../assets/rating/nonColored/great.svg";
import { ReactComponent as ReactColoredGreatIcon } from "../../assets/rating/colored/great.svg";
import RatingModal from "./ratingModal";
import { Helmet } from "react-helmet";

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const initialSelectedOrder: SelectedOrder = { orderId: null, rating: null, service: null};
  const [selectedOrder, setSelectedOrder] = useState<SelectedOrder>(initialSelectedOrder);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    setShouldAnimateOut(false);
  };

  const closeModal = () => {
    setShouldAnimateOut(true);
    setSelectedOrder(initialSelectedOrder);
  };

  useEffect(() => {
    if (shouldAnimateOut) {
      const timer = setTimeout(() => {
        setModalIsOpen(false);
      }, 300); // Match the duration of the slide-out animation
      return () => clearTimeout(timer);
    }
  }, [shouldAnimateOut]);


  const handleIconClick = (orderId: number, rating: number, service: Service) => {
    setSelectedOrder({ orderId, rating, service });
    openModal()
  };

  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}services/orders`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setOrders(response.data);
      } catch (error) {
        console.error("There was an error fetching the services!", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [selectedOrder]);

  const renderContent = (order: Order) => {
    switch (order.service.code) {
      case 1:
        return (
          <>
            <p className={styles.title}>
              {order.service.name.charAt(0).toUpperCase() + order.service.name.slice(1)}
            </p>
            <div>
              <p
                className={`${styles.status} ${
                  styles[order.status.toLowerCase()]
                }`}
              >
                {order.status}
              </p>
              <p className={styles.time}>
                • {convertDateTimeBack(order.date, order.time).dateString},{" "}
                {convertDateTimeBack(order.date, order.time).timeStr}
              </p>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <p className={styles.title}>
            {order.service.name.charAt(0).toUpperCase() + order.service.name.slice(1)}
            </p>
            <div>
              <p
                className={`${styles.status} ${
                  styles[order.status.toLowerCase()]
                }`}
              >
                {order.status}
              </p>
              <p className={styles.time}>
                • Drop off: {convertDateBackOnly(order.dropoffDate)},{" "}
                {order.timeSlots.slot}
              </p>
            </div>
          </>
        );
      case 3:
          return (
            <>
              <p className={styles.title}>
              {order.service.name.charAt(0).toUpperCase() + order.service.name.slice(1)}
              </p>
              <div>
                <p
                  className={`${styles.status} ${
                    styles[order.status.toLowerCase()]
                  }`}
                >
                  {order.status}
                </p>
                <p className={styles.time}>
                  • Delivery: in 30 - 45 min
                </p>
              </div>
            </>
          );
          case 4:
            return (
              <>
                <p className={styles.title}>
                {order.service.name.charAt(0).toUpperCase() + order.service.name.slice(1)}
                </p>
                <div>
                  <p
                    className={`${styles.status} ${
                      styles[order.status.toLowerCase()]
                    }`}
                  >
                    {order.status}
                  </p>
                  <p className={styles.time}>• Deliver in 30 mins</p>
                </div>
              </>
            );
          case 5:
            return (
              <>
                <p className={styles.title}>
                {order.service.name.charAt(0).toUpperCase() + order.service.name.slice(1)}
                </p>
                <div>
                  <p
                    className={`${styles.status} ${
                      styles[order.status.toLowerCase()]
                    }`}
                  >
                    {order.status}
                  </p>
                  <p className={styles.time}>• Delivery: in 30 - 45 min</p>
                </div>
              </>
            );
      default:
        return <p>No content available</p>;
    }
  };

  const handleOrderClick = (order: Order) => {
    switch (order.service.code) {
      case 1:
        navigate(`/app/orders/housekeeping/${order.service.code}/${order.id}`)
        break;
      case 2:
        navigate(`/app/orders/laundry/${order.service.code}/${order.id}`) 
        break;
      case 3:
        navigate(`/app/orders/food/${order.service.code}/${order.id}`)
        break;
      case 4:
        navigate(`/app/orders/freshTowels/${order.service.code}/${order.id}`)
        break;
      case 5:
        navigate(`/app/orders/groceries/${order.service.code}/${order.id}`)
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.upper}>
            <div
              className={styles.backArrow}
              onClick={() => {
                navigate("/app");
              }}
            >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
            </svg>
            </div>
          </div>
          <div className={styles.title}>
            <h1>Orders</h1>
        </div>
        </header>
        <div className={styles.orders}>
          <div className={styles.ordersItems}>
            {loading ? (
              <>
                {[1, 2, 3, 4].map((_, index) => (
                  <div key={index} className={`pl-5 pb-5 ${styles.orderItem}`}>
                    {imgTwoTexts()}
                  </div>
                ))}
              </>
            ) : (
              orders.map((order, index) => (
                <div
                  className={`flex flex-col ${styles.orderItem}`}
                  key={index}
                >
                  <div onClick={() => handleOrderClick(order)} className="flex">
                    <div className={styles.imageContainer}>
                      <img
                        src={order.service.image}
                        alt={order.service.name}
                      />
                    </div>
                    <div className={styles.data}>{renderContent(order)}</div>
                  </div>
                  {(!order.isOrderRated && order.status === "Completed") && (
                    <div className={styles.rating}>
                      <p>How was your experience?</p>
                      <div className={`flex ${styles.faces}`}>
                        <span onClick={() => handleIconClick(order.id, 1, order.service)}>
                          {selectedOrder.orderId === order.id && selectedOrder?.service?.code === order.service.code && selectedOrder.rating === 1 ? <ReactColoredTerribleIcon /> : <ReactTerribleIcon />}
                        </span>
                        <span onClick={() => handleIconClick(order.id, 2, order.service)}>
                          {selectedOrder.orderId === order.id && selectedOrder?.service?.code === order.service.code && selectedOrder.rating === 2 ? <ReactColoredBadIcon /> : <ReactBadIcon />}
                        </span>
                        <span onClick={() => handleIconClick(order.id, 3, order.service)}>
                          {selectedOrder.orderId === order.id && selectedOrder?.service?.code === order.service.code && selectedOrder.rating === 3 ? <ReactColoredMehIcon /> : <ReactMehIcon />}
                        </span>
                        <span onClick={() => handleIconClick(order.id, 4, order.service)}>
                          {selectedOrder.orderId === order.id && selectedOrder?.service?.code === order.service.code && selectedOrder.rating === 4 ? <ReactColoredGoodIcon /> : <ReactGoodIcon />}
                        </span>
                        <span onClick={() => handleIconClick(order.id, 5, order.service)}>
                          {selectedOrder.orderId === order.id && selectedOrder?.service?.code === order.service.code && selectedOrder.rating === 5 ? <ReactColoredGreatIcon /> : <ReactGreatIcon />}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
            {!loading && orders.length <= 0 && <EmptyOrders />}
          </div>
          <div className={`${styles.footer} ${ orders.length <= 0 ? styles.emptyOrders : ''}`}>
            {!loading && orders.length <= 0 ? (
              <button
                onClick={() => navigate("/app")}
                className={styles.backHome}
              >
                Go back home
              </button>
            ) : (
              <p>
                For security reasons, all completed or cancelled orders will be
                removed from order history after 24 hours.
              </p>
            )}
          </div>
        </div>
      </div>
      {selectedOrder.orderId && <RatingModal selectedOrder={selectedOrder} shouldAnimateOut={shouldAnimateOut} isOpen={modalIsOpen} onRequestClose={closeModal} />}
    </>
  );
};

export default Orders;
