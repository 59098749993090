import React from 'react';
import styles from '../styles/loader.module.css';


const Loader = () => {
  return (
    <div className={`${styles.loader}`}>
        <div className={styles.container}>
        </div>
    </div>
  );
};

export default Loader;
