import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styles from '../../styles/groceryService/itemModal.module.css';
import subIcon from '../../assets/icons/sub.svg';
import { ReactComponent as ReactplusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as ReactColoredsubIcon } from '../../assets/icons/coloredSub.svg';

interface CustomModalProps {
  isOpen: boolean;
  item: any;
  onRequestClose: () => void;
  shouldAnimateOut: boolean;
}

const ItemModal: React.FC<CustomModalProps> = ({
  isOpen,
  item,
  onRequestClose,
  shouldAnimateOut,
}) => {
  const [count, setCount] = useState<number>(1);
  const [orderItems, setOrderItems] = useState<{
    [key: number]: { quantity: number; data: any };
  }>({});

  useEffect(() => {
    if (isOpen) {
      const groceries = localStorage.getItem('groceryItems');
      let parsedOrders :any = {};
      if (groceries) {
        parsedOrders = JSON.parse(groceries);
      }
      const existingItem = parsedOrders[item.id];
      setOrderItems(parsedOrders);
      setCount(existingItem ? existingItem.quantity : 1);
    }
  }, [isOpen, item.id]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }

    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [isOpen]);

  const increment = () => {
    if (count < item.maxOrderQuantity) {
      setCount(count + 1);
    }
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const handleSubmit = () => {
    // Update the cart in localStorage
    const updatedOrderItems = {
      ...orderItems,
      [item.id]: {
        ...item,
        quantity: count,
      },
    };
    localStorage.setItem('groceryItems', JSON.stringify(updatedOrderItems));
    // Close the modal
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      className={`${styles.itemModal} ${shouldAnimateOut ? styles.exit : ''}`}
      overlayClassName={`${shouldAnimateOut ? styles.exitOverlay : ''}`}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 100,
        },
      }}
    >
      <div className={styles.header}>
        <button onClick={onRequestClose}>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
                    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#E4E8EC"/>
                    <path d="M25 15L15 25M15 15L25 25L15 15Z" stroke="#323E48" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
        </button>
        <div className={styles.imageContainer}>
          <img src={item.imageUrls['200_200'] || "/services/groceryitem.png"} alt={item.name} />
        </div>
        <h4>{item.description}</h4>
      </div>
      <div className={`flex ${styles.footer}`}>
        <div className={styles.counter}>
          <button
            className={styles.button}
            onClick={decrement}
            disabled={count === 1}
          >
            {count > 1 ? (
              <ReactColoredsubIcon />
            ) : (
              <img src={subIcon} alt="Subtract Icon" />
            )}
          </button>
          <span className={styles.count}>{count}</span>
          <button
            disabled={count === item.maxOrderQuantity}
            className={styles.button}
            onClick={increment}
          >
            <ReactplusIcon />
          </button>
        </div>
        <button
          onClick={handleSubmit}
          className={`flex justify-between ${styles.continue}`}
        >
          <p>Add</p>
          <p>AED {(count * item.grossPrice).toFixed(2)}</p>
        </button>
      </div>
    </Modal>
  );
};

export default ItemModal;
