import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import styles from "../../styles/selectTime.module.css";
import {
  generateDropoffDates,
  getDropoffTimes,
} from "../../utils/times/dropoffTimeDate";
import { twoRowTexts } from "../../utils/skeleton";
import Modal from "react-modal";

interface DropoffTimeProps {
  onConfirm: (dropoffDate: string, dropoffTime: string) => void;
  pickupDate: string;
  isOpen: boolean;
  onRequestClose: () => void;
  shouldAnimateOut: boolean
}

export type DropoffTime = {
  createdAt: string;
  extraFee: string;
  id: number;
  slot: string;
  type: string;
  updatedAt: string;
};

const DropoffTimes: React.FC<DropoffTimeProps> = ({
  pickupDate,
  onConfirm,
  isOpen,
  onRequestClose,
  shouldAnimateOut
}) => {
  const [dateSelected, setDateSelected] = useState("");
  const [timeSelected, setTimeSelected] = useState<DropoffTime | null>(null);
  const [times, setTimes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleContinueClick = () => {
    const selectedData: any = {
      dropoffDate: dateSelected,
      dropoffTime: timeSelected,
    };
    onConfirm(selectedData.dropoffDate, selectedData.dropoffTime);
    onRequestClose();
  };

  const handleDateClick = (value: string) => {
    setDateSelected(value);
  };

  const handleTimeClick = (value: DropoffTime) => {
    setTimeSelected(value);
  };

  useEffect(() => {
    const fetchTimes = async () => {
      try {
        const timesData = await getDropoffTimes();
        if (timesData) {
          setTimes(timesData);
          setDateSelected(generateDropoffDates(pickupDate)[0]);
          setTimeSelected(timesData[0]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTimes();
  }, [pickupDate]);

  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    className={`${styles.modalContent} ${shouldAnimateOut ? styles.exit : ''}`}
    overlayClassName={` ${styles.modalOverlay} ${shouldAnimateOut ? styles.exitOverlay : ''}`}
    >

      <div className={`flex flex-col  ${styles.container}`}>
      <h4>Select drop off date and time</h4>
        <div className={`flex ${styles.select} ${styles.modalselect}`}>
          <div className={`${styles.date}`}>
            {generateDropoffDates(pickupDate).map(
              (date: string, index: number) => (
                <div
                  key={index}
                  onClick={() => handleDateClick(date)}
                  className={`${styles.selectBox} ${
                    dateSelected === date ? `${styles.selected}` : ""
                  }`}
                >
                  <p>{date.split(", ")[0]}</p>
                  <p>{date.split(", ")[1]}</p>
                </div>
              )
            )}
          </div>
          <div className={`${styles.time}`}>
            {loading ? (
              <>
                <div className={`${styles.skeletonLoading}`}>
                  {twoRowTexts()}
                </div>
                <div className={styles.skeletonLoading}>{twoRowTexts()}</div>
                <div className={styles.skeletonLoading}>{twoRowTexts()}</div>
                <div className={styles.skeletonLoading}>{twoRowTexts()}</div>
              </>
            ) : (
              <>
                {timeSelected &&
                  times.map((time: DropoffTime, index: number) => (
                    <div
                      key={index}
                      onClick={() => handleTimeClick(time)}
                      className={`flex flex-col ${styles.feesSelectBox} ${
                        styles.selectBox
                      } ${
                        timeSelected.slot === time.slot
                          ? `${styles.selected}`
                          : ""
                      }`}
                    >
                      <p>{time.slot}</p>
                      {parseFloat(time.extraFee) > 0 && (
                        <div className={styles.extraFee}>
                          +AED {time.extraFee}
                        </div>
                      )}
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
        <div className={`${styles.footer}`}>
          <button onClick={handleContinueClick}>Confirm</button>
        </div>
      </div>
    </Modal>
  );
};

export default DropoffTimes;
