import { Routes, Route } from "react-router-dom";
import NotFound from "./NotFound";
import Dashboard from "./Dashboard";
import Checkout from "./cleaningService/checkout";
import Info from "./cleaningService/info";
import Orders from "./orders/orders";
import OrderDetail from "./orders/orderDetail";
import TowelCheckout from "./towelService/checkout";
import LaundryCheckout from "./laundryService/checkout";
import FoodCheckout from "./foodService/checkout";
import MenuItem from "./foodService/menuItem";
import GroceryCheckout from "./groceryService/checkout";
import CategoryItems from "./groceryService/categoryItems";
import SelectCategory from "../components/groceryService/selectCategory";


const Main: React.FC = () => {

  return (
      <div>
        <div >
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/:service/:code/:id" element={<OrderDetail />} />
            <Route path="/housekeeping/checkout" element={<Checkout />} />
            <Route path="/housekeeping/info" element={<Info />} />
            <Route path="/freshtowels/checkout" element={<TowelCheckout />} />
            <Route path="/laundry/checkout" element={<LaundryCheckout />} />
            <Route path="/food/checkout" element={<FoodCheckout />} />
            <Route path="/groceries/categories" element={<SelectCategory />} />
            <Route path="/groceries/categories/:id" element={<CategoryItems />} />
            <Route path="/groceries/checkout" element={<GroceryCheckout />} />
            <Route path="/food/checkout/menuitem/:id" element={<MenuItem/>} />

            <Route path="*" element={<NotFound />} />

          </Routes>
        </div>
      </div>
  );
};

export default Main;
