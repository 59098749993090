export const getMobileOperatingSystem = ()=> {
    let googlePay = false
    let applePay = false

    if (window.ApplePaySession) {
        applePay = true
      } else {
        applePay = false    
     }
  
      // Check for Google Pay support
      const userAgent = navigator.userAgent;
      if (/Android/i.test(userAgent)) {
        googlePay = true
      } else {
        googlePay = false
    }

    return {applePay, googlePay};
}