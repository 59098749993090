import React, { useEffect} from 'react';
import Modal from 'react-modal';
import styles from '../../styles/success.module.css';
import Success from './success';

interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const SuccessModal: React.FC<CustomModalProps> = ({ isOpen, onRequestClose}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }

    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [isOpen]);
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      className={styles.successModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }}}
    >
      <Success />
    </Modal>
  );
};

export default SuccessModal;
