// src/utils/importAllSvgs.ts

const importAllSvgs = (requireContext: any) => {
    let icons: { [key: string]: any } = {};
    requireContext.keys().forEach((key: string) => {
      const iconName = key.replace('./', '').replace('.svg', '');
      icons[iconName] = requireContext(key);
    });
    return icons;
  };
  
  export default importAllSvgs;
  