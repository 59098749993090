import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../styles/orderDetail.module.css";
import { Order } from "../../utils/types/services";
import axios from "axios";
import CancelationModal from "../../components/cancelationModal";
import Loader from "../../components/Loader";
import CleaningOrderHistory from "../../components/cleaningService/cleaningOrderHistory";
import TowelsOrderHistory from "../../components/towelsService/towelsOrderHistory";
import LaundryOrderHistory from "../../components/laundryService/laundryOrderHistory";
import FoodOrderHistory from "../../components/foodService/foodOrderHistory";
import { Helmet } from "react-helmet";
import GroceryOrderHistory from "../../components/groceryService/groceriesOrderHistory";
// import ContactModal from "../../components/contactModal";

const OrderDetail: React.FC = () => {
  const { id, code } = useParams<{ id: string; code: string }>();
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [cancelMIsOpen, setCancelMIsOpen] = useState(false);
  const navigate = useNavigate();


  const serviceName = () => {
    switch (Number(code)) {
      case 1:
        return "housekeeping";
      case 2:
        return "laundry";
      case 3:
        return "food";
      case 4:
        return "freshTowels";
      case 5:
        return "groceries";
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }
        if (!code) return;
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}services/${serviceName()}/orders/${id}`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setOrder(response.data);
      } catch (error) {
        navigate("/app/orders")
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const orderHistory = () => {
    if (!order) return;
    switch (order.service.code) {
      case 1:
        return <CleaningOrderHistory order={order} />;
      case 2:
        return <LaundryOrderHistory order={order} />;
      case 3:
        return <FoodOrderHistory order={order} />;
      case 4:
        return <TowelsOrderHistory order={order} />;
      case 5:
        return <GroceryOrderHistory order={order} />;
    }
  };

  const cancelIsOpen = () => {
    setCancelMIsOpen(true);
  };
  const closeCancel = () => {
    setCancelMIsOpen(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      {order && (
        <>
          {orderHistory()}
          {order.status === "In progress" && (
            <div className={styles.footer}>
              <button
                onClick={cancelIsOpen}
                className={!order.canCancel ? styles.cancelDim : ""}
                disabled={!order.canCancel}
              >
                Cancel order
              </button>
              <CancelationModal
                isOpen={cancelMIsOpen}
                onRequestClose={closeCancel}
                service={code && serviceName()}
                id={id}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OrderDetail;
