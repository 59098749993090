import React, { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import LaundrySelectItem from "../../components/laundryService/selectItem";
import LaundrySelectTime from "../../components/laundryService/selectTime";
import LaundryPayment from "../../components/laundryService/payment";
import "../../styles/switchTransition.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export type DropoffTime = {
  createdAt: string;
  extraFee: string;
  id: number;
  slot: string;
  type: string;
  updatedAt: string;
};

interface TimeData {
  pickupData: { pickupDate: string; pickupTime: string };
  dropoffData: { dropOffDate: string; dropOffTime: DropoffTime };
}

type LaundryProcessStep = "selectItem" | "selectTime" | "payment";

const LaundryCheckout: React.FC = () => {
  const [currentComponent, setCurrentComponent] = useState<LaundryProcessStep>("selectItem");
  const [selectedTimeData, setSelectedTimeData] = useState<TimeData | null>(null);
  const [isForward, setIsForward] = useState<boolean>(true);
  const [themeColor, setThemeColor] = useState('#ffffff'); 
  const navigate = useNavigate()

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}user-services/${2}`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        if (!response.data.isAvailable)
          navigate("/app")
      } catch (error) {
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    const primaryHeaderColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-header-color');
    setThemeColor(primaryHeaderColor.trim());
  }, []);

  const handleContinue = () => {
    setIsForward(true);
    setTimeout(() => {
      setCurrentComponent("selectTime");
    }, 100);
  };

  const handlePayment = (pickupData: any, dropoffData: any) => {
    setSelectedTimeData({ pickupData, dropoffData });
    setIsForward(true);
    setTimeout(() => {
      setCurrentComponent("payment");
    }, 100);
  };

  const handleBack = (previousStep: LaundryProcessStep) => {
    setIsForward(false);
    setTimeout(() => {
      setCurrentComponent(previousStep);
    }, 100);
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={currentComponent}
        timeout={200}
        classNames={isForward ? "fade-forward" : "fade-backward"}
      >
        <div className={`transition-wrapper`}>
          <>
            <Helmet>
                <meta name="theme-color" content={themeColor} />
            </Helmet>
            {currentComponent === "selectItem" && <LaundrySelectItem onContinue={handleContinue} />}
            {currentComponent === "selectTime" && (
              <LaundrySelectTime onBack={() => handleBack("selectItem")} onContinue={handlePayment} />
            )}
          </>
          {currentComponent === "payment" && selectedTimeData && (
            <>
              <Helmet>
                <meta name="theme-color" content="#fff" />
              </Helmet>
              <LaundryPayment
                onBack={() => handleBack("selectTime")}
                dropoffData={selectedTimeData.dropoffData}
                pickupData={selectedTimeData.pickupData}
              />
            </>
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default LaundryCheckout;
