import importAllSvgs from '../../utils/importAllSvgs';

const includeSvgIcons = importAllSvgs(require.context('/public/services/cleaning/faq/include', false, /\.svg$/));
const nIncludeSvgIcons = importAllSvgs(require.context('/public/services/cleaning/faq/nInclude', false, /\.svg$/));


export const professionalsData = [
    {
      content: "Trained cleaner",
    },
    {
      content: "100+ hrs of training by housekeeping experts",
    },
    {
      content: "Flexible to customers cleaning requirements",
    },
    {
      content: "3+ years of hands-on experience",
    },
  ];

  export const whatDoesItInclude = [
    {
      icon: includeSvgIcons['Vaccum'],
      content: "Vacuum & mopping",
    },
    {
      icon: includeSvgIcons['Kitchen'],
      content: "Kitchen cleaning",
    },
    {
      icon: includeSvgIcons['PlantsBalcony'],
      content: "Balcony cleaning",
    },
    {
      icon: includeSvgIcons['LaundryFolding'],
      content: "Ironing & folding",
    },
    {
      icon: includeSvgIcons['Closet'],
      content: "Cabinet cleaning",
    },
    {
      icon: includeSvgIcons['MildStain'],
      content: "Mild stain removal",
    },
    {
      icon: includeSvgIcons['Dishes'],
      content: "Dish washing",
    },
    {
      icon: includeSvgIcons['BedMasking'],
      content: "Bed masking",
    },
    {
      icon: includeSvgIcons['Dusting'],
      content: "Dusting",
    },
  ];

  export const whatDoesNotItInclude = [
    {
      icon: nIncludeSvgIcons['heavy'],
      content: "Heavy stain removal & deep cleaning",
    },
    {
      icon: nIncludeSvgIcons['facade'],
      content: "Cleaning building facade",
    },
    {
      icon: nIncludeSvgIcons['Surface'],
      content: "Surface descaling",
    },
    {
      icon: nIncludeSvgIcons['polishing'],
      content: "Bath fitting polishing",
    }
  ];
  

  export const HowLongDoesTheServiceTake = [
    {
      content: "Living room",
      estimate: "15-20 min",
    },
    {
      content: "Bedroom",
      estimate: "30 min",
    },
    {
      content: "Bathroom",
      estimate: "45 min",
    },
    {
      content: "Kitchen",
      estimate: "30-35 min",
    },
    {
      content: "Ironing (up to 5 items)",
      estimate: "30 min",
    },
    {
      content: "Balcony (light dusting)",
      estimate: "10-15 min",
    },
    {
      content: "Balcony (heavy dusting)",
      estimate: "30 min",
    },
  ];


  export const whatCleaningMaterialsAreUsed = [
    {
      content: "Steam Mop",
    },
    {
      content: "Vacuum cleaner",
    },
    {
      content: "5 cleaning solutions",
    },
    {
      content: "3 cleaning cloths",
    },
    {
      content: "Wiper & sponges",
    },
  ];



  export const cancellationPolicy = [
    {
      time: "12+ hours before the appointment",
      fee: "Free of charge",
    },
    {
      time: "2-12 hours before the appointment",
      fee: "25% of appointment value",
    },
    {
      time: "Less than 2 hours before the appointment",
      fee: "50% of appointment value",
    },
    {
      time: "Missed appointment",
      fee: "100% of appointment value",
    },
  ];