import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/tailwind.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkSavedPhoneNumber } from './utils/validations/phoneNumber';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


// Temp
checkSavedPhoneNumber()
root.render(
  <>
    <App />
    <ToastContainer
          style={{left: "50%", transform: "translate(-50%)", width: "max-content", maxWidth: "85%"}}
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
      />
  </>
);