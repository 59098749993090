import axios from "axios";
import { convertDateOnly } from "./timeDateConvert";

export const getDropoffTimes = async () => {
    try {
      const token = localStorage.getItem('PropertyToken');
      if (!token) {
        throw new Error('PropertyToken not found');
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}services/laundry/dropoffTimes`, {
        headers: {
          propertyToken: token
        }
      });
      return response.data
    } catch (error) {
      console.error('Error fetching sections with items:', error);
    }
}

export const generateDropoffDates = (pickUpDate: any) => {
    const dates = [];
    const today = new Date(convertDateOnly(pickUpDate));
    
    for (let i = 2; i < 7; i++) {
      
      const futureDate = new Date();
      futureDate.setFullYear(today.getFullYear());
      futureDate.setMonth(today.getMonth());
      futureDate.setDate(today.getDate() + i);
      const options: any = { weekday: "long", day: "numeric", month: "long" };

      let dateString;
      const now = new Date(convertDateOnly(pickUpDate));
      const currentHour = now.getHours();
      if (i === 0 && currentHour > 16) continue;
      if (i === 0 && currentHour < 16) {
        const dayMonth = futureDate.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
        });
        dateString = `Today, ${dayMonth}`;
      } else {
        dateString = futureDate.toLocaleDateString("en-US", options);
      }
      dates.push(dateString);
    }
    return dates;
  };