import styles from '../../styles/groceryService/emptyGroceryItems.module.css';

interface ItemsProps {
    searchQuery: string
}
const EmptyGroceryItems: React.FC<ItemsProps> = ({searchQuery}) => {

  
  return (
    <div className={styles.container}>
        <div className={styles.icon}>
            <svg width="181" height="181" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.9596 157.972C126.79 157.972 155.838 128.763 155.838 92.6047C155.838 56.4468 126.662 27.2373 90.9596 27.2373C55.1294 27.2373 26.0813 56.4468 26.0813 92.6047C26.0813 128.763 55.1294 157.972 90.9596 157.972Z" fill="#EAEEF9"/>
                <path d="M151.615 49.7565C154.512 49.7565 156.861 47.3945 156.861 44.4808C156.861 41.5671 154.512 39.2051 151.615 39.2051C148.717 39.2051 146.368 41.5671 146.368 44.4808C146.368 47.3945 148.717 49.7565 151.615 49.7565Z" fill="#EAEEF9"/>
                <path d="M159.293 29.1678C161.272 29.1678 162.876 27.5547 162.876 25.5648C162.876 23.575 161.272 21.9619 159.293 21.9619C157.314 21.9619 155.71 23.575 155.71 25.5648C155.71 27.5547 157.314 29.1678 159.293 29.1678Z" fill="#EAEEF9"/>
                <path d="M29.5359 49.6277C31.5147 49.6277 33.1189 48.0146 33.1189 46.0248C33.1189 44.035 31.5147 42.4219 29.5359 42.4219C27.5571 42.4219 25.9529 44.035 25.9529 46.0248C25.9529 48.0146 27.5571 49.6277 29.5359 49.6277Z" fill="#EAEEF9"/>
                <path d="M7.65418 119.112C11.3292 119.112 14.3084 116.116 14.3084 112.421C14.3084 108.725 11.3292 105.729 7.65418 105.729C3.97918 105.729 1 108.725 1 112.421C1 116.116 3.97918 119.112 7.65418 119.112Z" fill="#EAEEF9"/>
                <g filter="url(#filter0_d_7988_573)">
                <path d="M135.107 59.9223L136.131 142.79C136.131 145.62 133.828 147.808 131.012 147.808H50.6503C47.8351 147.808 45.5317 145.492 45.5317 142.79V36.2459C45.5317 33.415 47.8351 31.2275 50.6503 31.2275H108.874L135.107 59.9223Z" fill="url(#paint0_linear_7988_573)"/>
                </g>
                <path d="M84.6891 131.208H81.7459C81.1061 131.208 80.5942 130.564 80.5942 129.792C80.5942 129.02 81.1061 128.377 81.7459 128.377H84.6891C85.329 128.377 85.8408 129.02 85.8408 129.792C85.8408 130.693 85.329 131.208 84.6891 131.208Z" fill="#CED7E2"/>
                <path d="M75.7316 131.208H58.5843C57.9445 131.208 57.4326 130.564 57.4326 129.792C57.4326 129.02 57.9445 128.377 58.5843 128.377H75.7316C76.3714 128.377 76.8833 129.02 76.8833 129.792C76.8833 130.693 76.3714 131.208 75.7316 131.208Z" fill="#D5DDEA"/>
                <path d="M97.6136 48.5975H59.0962C58.2004 48.5975 57.4326 47.8255 57.4326 46.9247C57.4326 46.024 58.2004 45.252 59.0962 45.252H97.6136C98.5094 45.252 99.2772 46.024 99.2772 46.9247C99.2772 47.8255 98.5094 48.5975 97.6136 48.5975Z" fill="#D5DDEA"/>
                <path d="M73.9401 57.8622H59.0962C58.2004 57.8622 57.4326 57.0901 57.4326 56.1894C57.4326 55.2887 58.2004 54.5166 59.0962 54.5166H73.8121C74.7079 54.5166 75.4757 55.2887 75.4757 56.1894C75.4757 57.0901 74.7079 57.8622 73.9401 57.8622Z" fill="#D5DDEA"/>
                <path d="M108.875 31.2275V54.1319C108.875 57.3488 111.69 59.9223 114.889 59.9223H135.107" fill="#D5DDEA"/>
                <path d="M141.25 149.737C139.97 149.737 138.69 149.223 137.667 148.064L116.296 126.576L115.529 127.09C108.619 132.237 100.557 134.94 92.367 134.94C82.5136 134.94 72.6603 130.951 65.3663 124.002C57.6884 116.668 53.4656 106.76 53.4656 95.9507C53.4656 74.4618 70.8688 56.9619 92.239 56.9619C106.827 56.9619 119.624 64.6825 126.534 77.8074C133.316 90.8037 132.42 105.987 124.102 118.212L123.59 118.984L145.089 140.601C147.264 142.789 146.752 144.976 146.368 146.134C145.344 148.193 143.297 149.737 141.25 149.737ZM92.239 67.642C76.6273 67.642 64.0867 80.3809 64.0867 95.9507C64.0867 113.708 78.5467 124.388 92.6229 124.388C101.197 124.388 109.002 120.528 114.505 113.579C121.287 105.087 122.439 93.6346 117.704 83.7265C112.841 73.8185 103.116 67.642 92.239 67.642Z" fill="#989FB0"/>
                <path d="M80.0821 100.584C81.7457 100.584 83.1533 99.1682 83.1533 97.4955C83.1533 95.8227 81.7457 94.4072 80.0821 94.4072C78.4186 94.4072 77.011 95.8227 77.011 97.4955C77.011 99.1682 78.4186 100.584 80.0821 100.584Z" fill="#989FB0"/>
                <path d="M103.628 100.584C105.292 100.584 106.699 99.1682 106.699 97.4955C106.699 95.8227 105.292 94.4072 103.628 94.4072C101.964 94.4072 100.557 95.8227 100.557 97.4955C100.557 99.2969 101.964 100.584 103.628 100.584Z" fill="#989FB0"/>
                <path d="M79.9626 87.2783L73.2068 90.9463L74.1187 92.6446L80.8745 88.9766L79.9626 87.2783Z" fill="#989FB0"/>
                <path d="M103.211 87.2302L102.299 88.9287L109.055 92.596L109.967 90.8974L103.211 87.2302Z" fill="#989FB0"/>
                <path d="M91.855 107.789C93.8338 107.789 95.438 106.579 95.438 105.087C95.438 103.595 93.8338 102.385 91.855 102.385C89.8761 102.385 88.272 103.595 88.272 105.087C88.272 106.579 89.8761 107.789 91.855 107.789Z" fill="#989FB0"/>
                <defs>
                <filter id="filter0_d_7988_573" x="23.5317" y="20.2275" width="134.599" height="160.58" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="11"/>
                <feGaussianBlur stdDeviation="11"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7988_573"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7988_573" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_7988_573" x1="90.8018" y1="28.5309" x2="90.8018" y2="149.065" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FDFEFF"/>
                <stop offset="0.9964" stop-color="#ECF0F5"/>
                </linearGradient>
                </defs>
            </svg>
        </div>
        <h3>We couldn’t find “{searchQuery}”</h3>
        <p>Try another search</p>

    </div>
  );
};

export default EmptyGroceryItems;
