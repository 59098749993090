import applePayIcon from '../assets/paymentMethods/Apple.svg'
import cardIcon from '../assets/paymentMethods/card.svg'
import googleIcon from '../assets/paymentMethods/google.svg'
import { getMobileOperatingSystem } from './operatingSystemCheck';
const {applePay, googlePay} = getMobileOperatingSystem()

export const paymentMethods = [
  ...(applePay ? [{ icon: applePayIcon, label: 'Apple Pay', value: 'apple_pay' }] : []),
  ...(googlePay ? [{ icon: googleIcon, label: 'Google Pay', value: 'google_pay' }] : []),
  { icon: cardIcon, label: 'Bank Card', value: 'card' },
];
