import React, { useState } from 'react';
import styles from '../styles/mobileNumber.module.css'
import countries from '../assets/countries.json';
import Select, { SingleValue, components } from 'react-select';
import { Country } from '../utils/types/services';
import { formatPhoneNumber, isValidNumber } from '../utils/validations/phoneNumber';
import notify from './Errortoast';

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 6L8 10L12 6" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    </components.DropdownIndicator>
  );
};

// Define custom styles
const customStyles: any = {
  control: (provided: any) => ({
    ...provided,
    display: 'flex',
    width: '86px',
    border: '1px solid #E1E5EA', 
    '&:hover': {
      borderColor: '#E1E5EA', // Keep the same border color on hover
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0',
    paddingLeft: '13px',
    alignItems: 'center',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0',
    paddingRight: '13px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none'
  }),
  input: (provided: any) => ({
    ...provided,
    display:'none'
  }),
};
interface MobileNumberProps {
  onProceed: (phoneNumber: string) => void;
  }

const MobileNumber: React.FC<MobileNumberProps> = ({onProceed}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const defaultCountry = countries.find((country) => country.code === 'AE') as Country;
  const [selectedCountry, setSelectedCountry] = useState<Country>(defaultCountry);
  
  const handleCountryChange = (selectedOption: SingleValue<Country>) => {
    if (selectedOption) {
      setSelectedCountry(selectedOption);
      setPhoneNumber('');
    }
  };

  const formatOptionLabel = (option: Country) => (
    <div>
      <span>{option.emoji}</span> {option.name} ({option.dial_code})
    </div>
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if(!isValidNumber(phoneNumber, selectedCountry)){
      notify({ type: 'error', content: 'Invalid Phone number!' });
      return
    }

    // setPhoneNumber(selectedCountry.dial_code + phoneNumber.trim())
    const trimedNumber = selectedCountry.dial_code + " " + phoneNumber;
    const phoneNumberObj = {number: trimedNumber, country: selectedCountry.code}
    localStorage.setItem('phoneNumber', JSON.stringify(phoneNumberObj));
    onProceed(trimedNumber);
    
  };

  const CustomSingleValue: React.FC<any> = ({ data }) => (
    <div>
      {data.dial_code}
    </div>
  );


  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^\d]/g, '');
    setPhoneNumber(formatPhoneNumber(selectedCountry, rawValue));
  };

  return (
    <>
        <span>
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.5 8.33333V31.6667C30.5 33.5076 29.0076 35 27.1667 35H13.8333C11.9924 35 10.5 33.5076 10.5 31.6667V8.33333C10.5 6.49238 11.9924 5 13.8333 5H27.1667C29.0076 5 30.5 6.49238 30.5 8.33333" stroke="url(#paint0_linear_5544_41328)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.1667 30H23.8334" stroke="url(#paint1_linear_5544_41328)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
            <linearGradient id="paint0_linear_5544_41328" x1="10.5" y1="26.25" x2="30.9018" y2="25.0698" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3B51F9"/>
            <stop offset="1" stop-color="#5EBFFB"/>
            </linearGradient>
            <linearGradient id="paint1_linear_5544_41328" x1="17.1667" y1="30.3472" x2="23.2564" y2="28.2335" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3B51F9"/>
            <stop offset="1" stop-color="#5EBFFB"/>
            </linearGradient>
            </defs>
          </svg>
        </span>
        <div className={`flex flex-col ${styles.phoneData}`}>
          <h4 className='text-center'>Add your whatsapp number:</h4>
          <p className='text-center'>We will only use this number to contact you in case there is any problem with your request.</p>
          <div className={styles.phoneInputContainer}>
            <Select
              options={countries}
              formatOptionLabel={formatOptionLabel}
              getOptionValue={(option) => option.dial_code}
              onChange={handleCountryChange}
              className={styles.selectBtn}
              getOptionLabel={(option) => option.dial_code}
              defaultValue={defaultCountry}
              isSearchable
              styles={customStyles}
              components={{ DropdownIndicator: CustomDropdownIndicator, SingleValue: CustomSingleValue  }} // Use the custom dropdown indicator
            />
            <input
              type="text"
              className={styles.phoneInput}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder={"Mobile number"}
            />
          </div>
          <button  disabled={!isValidNumber(phoneNumber, selectedCountry)} type="submit" onClick={handleSubmit} className={styles.pay}>Confirm number</button>
        </div>
        </>
  );
};

export default MobileNumber;
