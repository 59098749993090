import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/dashboard.module.css";
import axios from "axios";
import { Service } from "../utils/types/services";
import NavBar from "../components/navbar/NavBar";
import NewOrders from "../components/newOrders";
import { imgTwoTexts } from "../utils/skeleton";
import ImageWithLoader from "../components/imageWithLoader";
import { Helmet } from "react-helmet";

const Dashboard: React.FC = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const [topPosition, setTopPosition] = useState(-15);
  const navigate = useNavigate();
  const [themeColor, setThemeColor] = useState("#ffffff");

  useEffect(() => {
    const primaryHeaderColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--primary-app-header-color");
    setThemeColor(primaryHeaderColor.trim());
  }, []);

  const navigation = (service: Service) => {
    switch (service.service?.code || service.code) {
      case 1:
        navigate(`/app/housekeeping/checkout`);
        break;
      case 2:
        navigate(`/app/laundry/checkout`);
        break;
      case 3:
        navigate(`/app/food/checkout`);
        break;
      case 4:
        navigate(`/app/freshTowels/checkout`);
        break;
      case 5:
        navigate(`/app/groceries/categories`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}services`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setServices(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={"#4D80A4"} />
      </Helmet>
      <NavBar />
      <div className={styles.header}>
        <h2>
          What can
          <br />
          I do for
          <br />
          <span>you?</span>
        </h2>
      </div>
      <div className={styles.services}>
        <div style={{ top: `${topPosition}px` }} className={styles.container}>
          {loading ? (
            <>
              {[1, 2, 3, 4].map((_, index) => (
                <div key={index}>{imgTwoTexts()}</div>
              ))}
            </>
          ) : (
            <>
              {services.map((service) => (
    <div
    key={service.id}
    className={`${styles.sectionItem} flex flex-row flex-wrap items-center mt-2`}
    onClick={() => service.isAvailable && navigation(service)}
    style={{ cursor: service.isAvailable ? 'pointer' : 'not-allowed' }} // Optional: change cursor based on availability
    >
    <div className={`${styles.imageContainer} ${!service.isAvailable ? styles.unavailableImage : ''} `}>
      <ImageWithLoader
        src={service.service?.image || service.image}
        alt={service.service?.name || service.name}
      />
    </div>
    <article className="ml-2 flex-1">
      <div className="flex flex-row flex-wrap items-center">
        <h2 className={!service.isAvailable ? styles.disabledText : ''}>
          {service.service?.name || service.name}
        </h2>
        {service.price && (
          <p
            className={`${styles.price} ${!service.isAvailable ? styles.disabledText : ''}`}
          >
            AED {service.price}
          </p>
        )}
      </div>
      {service.isAvailable ? (
        <p>{service.description}</p>
      ) : (
        <p className={styles.descriptionUnavailable}>
          Service temporarily unavailable
        </p>
      )}
    </article>
  </div>
              ))}
              <div className={styles.newOrders} ref={containerRef}>
                <NewOrders />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
