import React from "react";
import styles from "../styles/notfound.module.css"; // Import the CSS module
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <div
      className={`${styles.section} flex flex-col justify-center items-center`}
    >
      <img src="/50.svg" alt="" />
      <div className={styles.page}>
        <p>Sorry, we couldn’t find anything on this page</p>
      </div>

      <Link to="/app" className={styles.backHome}>
        Go back home
      </Link>
    </div>
  );
};

export default NotFound;
