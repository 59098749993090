import React, { useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import styles from "../../styles/stripeCard.module.css";
import styled from "styled-components";
import notify from "../Errortoast";
import LoaderIcon from "../loaderIcon";
import SuccessModal from "./successModal";
import { convertDateOnly } from "../../utils/times/timeDateConvert";
import { getMobileOperatingSystem } from "../../utils/operatingSystemCheck";
import { useNavigate } from "react-router-dom";

export type DropoffTime = {
  createdAt: string;
  extraFee: string;
  id: number;
  slot: string;
  type: string;
  updatedAt: string;
};

interface PaymentProps {
  checkoutData: any;
  paymentMethod: string;
  additionalNotes?: string;
  phoneNumber: {number: string, country: string};
  pickupData: { pickupDate: string; pickupTime: string };
  dropoffData: { dropOffDate: string; dropOffTime: DropoffTime };
}

const CardElementWrapper = styled.div`
  .StripeElement {
    padding: 16px 10px 10px 10px;
    border-bottom: 1px solid #e4e8ec;
    border-radius: 1px;
    transition: border-color 0.3s ease;
  }

  .StripeElement--focus {
    border-color: #323e48;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

const cardElementStyles = {
  style: {
    base: {
      color: "#8B98A4",
      fontFamily: "Lexend",
      fontSmoothing: "antialiased",
      fontSize: "14px",
      "::placeholder": {
        color: "#8B98A4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const LaundryPaymentCard: React.FC<PaymentProps> = ({
  pickupData,
  dropoffData,
  checkoutData,
  paymentMethod,
  additionalNotes,
  phoneNumber,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(true);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const token = localStorage.getItem("PropertyToken");
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { applePay, googlePay } = getMobileOperatingSystem();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate()
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const createPaymentIntent = async () => {
    if (!token || parseFloat(checkoutData.total) === 0) {
      setLoading(false)
      return;
    }

    try {
      const counts = localStorage.getItem("counts");
      if (!counts || JSON.stringify(counts) === "{}") return;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}services/laundry/create-payment-intent`,
        {
          pickupData: pickupData,
          dropoffData: dropoffData,
          items: JSON.parse(counts),
        },
        {
          headers: {
            propertyToken: token,
          },
        }
      );
      setClientSecret(response.data.clientSecret);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    createPaymentIntent();

    setTimeout(() => {
      navigate(0)
    }, 600000);
  }, []);

  useEffect(() => {
    if (!stripe || !elements || !clientSecret) {
      return;
    }

    setLoading(true)
    const pr = stripe.paymentRequest({
      country: "AE",
      currency: "aed",
      total: {
        label: "Total",
        amount: Math.round(checkoutData.total * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    if (pr) {
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
        setLoading(false)
      });

      pr.on("paymentmethod", async (event) => {
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id,
          },
          { handleActions: false }
        );

        if (error) {
          event.complete("fail");
          console.error(error);
          return;
        } else {
          event.complete("success");
          if (paymentIntent && paymentIntent.status === "succeeded") {
            handlePaymentSuccess(paymentIntent);
          }
        }
      });
    }
  }, [stripe, elements, clientSecret]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    setIsSubmitting(true);
    const cardElement = elements.getElement(CardElement);
    const token = localStorage.getItem("PropertyToken");

    try {
      if (parseFloat(checkoutData.total) === 0) {
        if (!token) {
          return;
        }
        const counts = localStorage.getItem("counts");
        if (!counts || JSON.stringify(counts) === "{}") return;
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}services/laundry/store-zero-payment-data`,
          {
            paymentId: null,
            status: "succeeded",
            dropoffTimeId: dropoffData.dropOffTime.id,
            dropoffDate: convertDateOnly(dropoffData.dropOffDate),
            pickupTime: pickupData.pickupTime,
            pickupDate: convertDateOnly(pickupData.pickupDate),
            items: JSON.parse(counts),
            additionalNotes,
            paymentMethod,
            phoneNumber,
          },
          {
            headers: {
              propertyToken: token,
            },
          }
        );

        if (response.status === 200) {
          openModal();
          localStorage.removeItem("counts");
        } else {
          setIsSubmitting(false)
        }

      } else {
        if (!cardElement || !clientSecret) {
          notify({ type: "error", content: "Payment information incomplete." });
          setIsSubmitting(false);
          return;
        }
  
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      if (error) {
        setIsSubmitting(false)
        notify({ type: "error", content: `Payment error: ${error.message}` });
        return;
      }
      if (paymentIntent && paymentIntent.status === "succeeded") {
        if (!token) {
          return;
        }
        const counts = localStorage.getItem("counts");
        if (!counts || JSON.stringify(counts) === "{}") return;
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}services/laundry/store-payment-data`,
          {
            paymentId: paymentIntent.id,
            status: paymentIntent.status,
            dropoffTimeId: dropoffData.dropOffTime.id,
            dropoffDate: convertDateOnly(dropoffData.dropOffDate),
            pickupTime: pickupData.pickupTime,
            pickupDate: convertDateOnly(pickupData.pickupDate),
            items: JSON.parse(counts),
            additionalNotes,
            paymentMethod,
            phoneNumber,
          },
          {
            headers: {
              propertyToken: token,
            },
          }
        );

        if (response.status === 200) {
          openModal();
          localStorage.removeItem("counts");
        } else {
          setIsSubmitting(false)
        }
      } else {
        setIsSubmitting(false)
      }
    }
    } catch (error) {
      notify({ type: "error", content: `Something went wrong, please try again!` });
      // localStorage.removeItem("counts");
      // window.location.reload()
      setIsSubmitting(false)
    } finally {
    }
  };

  const handlePaymentSuccess = async (paymentIntent: any) => {
    setLoading(true);
    const token = localStorage.getItem("PropertyToken");

    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      const counts = localStorage.getItem("counts");
      if (!counts || JSON.stringify(counts) === "{}") return;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}services/laundry/store-payment-data`,
        {
          paymentId: paymentIntent.id,
          status: paymentIntent.status,
          dropoffTimeId: dropoffData.dropOffTime.id,
          dropoffDate: convertDateOnly(dropoffData.dropOffDate),
          pickupTime: pickupData.pickupTime,
          pickupDate: convertDateOnly(pickupData.pickupDate),
          items: JSON.parse(counts),
          additionalNotes,
          paymentMethod,
          phoneNumber,
        },
        {
          headers: {
            propertyToken: token,
          },
        }
      );
      setLoading(false);
      if (response.status === 200) {
        openModal();
        localStorage.removeItem("counts");
      } else {
        console.error("Failed to store payment data:", response);
      }
    } catch (error) {
      console.error("Error storing payment data:", error);
    } finally {
    }
  };

  const handleClick = () => {
    if (paymentRequest) {
      setIsSubmitting(true)    
      paymentRequest.show();
      setIsSubmitting(false)
    }
  };

  if (loading) {
    return <LoaderIcon />;
  }
  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
      {parseFloat(checkoutData.total) === 0 &&
          <button
          className={`flex justify-center ${styles.cardButton} ${isSubmitting ?  `${styles.submiting}` : ""}` }
          disabled={isSubmitting}
        >
          Order now
        </button>
}
        {paymentRequest &&
          paymentMethod !== "card" &&
          (applePay || googlePay) && (
            <button
              type="button"
              onClick={handleClick}
              className={`flex justify-center ${styles.applePayButton} ${isSubmitting ?  `${styles.submiting}` : ""}` }
              disabled={isSubmitting}
            >
              <span>
                {applePay && paymentMethod === "apple_pay" && (
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0654 6.17128C15.6511 5.41579 16.0694 4.40845 15.9439 3.40112C15.0654 3.4431 14.0194 3.98873 13.3919 4.70226C12.848 5.33184 12.346 6.38115 12.4715 7.3465C13.4756 7.47242 14.4378 6.92678 15.0654 6.17128ZM15.9439 7.59834C14.5215 7.51439 13.3082 8.39581 12.6388 8.39581C11.9695 8.39581 10.9236 7.64031 9.79398 7.64031C8.32971 7.68228 6.99094 8.47975 6.23789 9.82286C4.73178 12.4671 5.81953 16.3705 7.32563 18.5111C8.03685 19.5604 8.91542 20.7356 10.045 20.6936C11.1327 20.6517 11.5511 19.9801 12.848 19.9801C14.145 19.9801 14.5215 20.6936 15.6929 20.6517C16.8643 20.6097 17.6174 19.6024 18.3286 18.5111C19.1653 17.2939 19.5 16.1187 19.5 16.0767C19.4582 16.0347 17.199 15.1953 17.199 12.593C17.1572 10.4105 18.9561 9.36117 19.0398 9.3192C18.0776 7.8082 16.4878 7.64031 15.9439 7.59834Z"
                      fill="white"
                    />
                  </svg>
                )}
                {googlePay && paymentMethod === "google_pay" && (
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.4586 10.2456C20.4586 9.51343 20.3927 8.81284 20.2702 8.13916H10.7936V11.9988L16.2514 12C16.03 13.2842 15.3176 14.3789 14.2261 15.1087V17.6128H17.4748C19.3718 15.869 20.4586 13.2912 20.4586 10.2456Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M14.2273 15.1088C13.3229 15.7146 12.1584 16.069 10.796 16.069C8.16427 16.069 5.93172 14.3076 5.13219 11.9333H1.78101V14.5158C3.44129 17.7883 6.85371 20.0339 10.796 20.0339C13.5208 20.0339 15.8098 19.1439 17.476 17.6117L14.2273 15.1088Z"
                      fill="#34A853"
                    />
                    <path
                      d="M4.81657 10.0175C4.81657 9.35087 4.92843 8.70643 5.13214 8.10058V5.51813H1.78096C1.09447 6.87134 0.708252 8.39883 0.708252 10.0175C0.708252 11.6362 1.09565 13.1637 1.78096 14.5169L5.13214 11.9345C4.92843 11.3286 4.81657 10.6842 4.81657 10.0175Z"
                      fill="#FABB05"
                    />
                    <path
                      d="M10.796 3.96491C12.2832 3.96491 13.615 4.47368 14.6665 5.46783L17.5455 2.61052C15.7969 0.992982 13.5172 0 10.796 0C6.85488 0 3.44129 2.24561 1.78101 5.51812L5.13219 8.10058C5.93172 5.72631 8.16427 3.96491 10.796 3.96491Z"
                      fill="#E94235"
                    />
                  </svg>
                )}
              </span>
              <span>Pay AED {checkoutData.total}</span>
            </button>
          )}
        {parseFloat(checkoutData.total) !== 0 && paymentMethod === "card" && (
          <>
            <CardElementWrapper>
              <CardElement
                options={{ ...cardElementStyles, hidePostalCode: true }}
              />
            </CardElementWrapper>
            <button
              className={`${styles.cardButton} ${
                isSubmitting ? styles.submiting : " "
              }`}
              type="submit"
              disabled={!stripe || !clientSecret || isSubmitting}
            >
              Procced
            </button>
          </>
        )}
      </form>
      <SuccessModal
        items={checkoutData.itemsData}
        pickupData={pickupData}
        dropoffData={dropoffData}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      />
    </>
  );
};

export default LaundryPaymentCard;
