import React, { useEffect, useState } from "react";
import styles from "../../styles/groceryService/basket.module.css";
import "react-loading-skeleton/dist/skeleton.css";
import { ReactComponent as ReactplusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as ReactColoredsubIcon } from '../../assets/icons/coloredSub.svg';
import { ReactComponent as ReacttrashIcon } from '../../assets/icons/trash.svg';
import axios from "axios";

interface PaymentProps {
  onBack: () => void;
  onContinue: (allowSubstitution: boolean) => void;
}

const Basket: React.FC<PaymentProps> = ({ onBack, onContinue }) => {
  const [allowSubstitution, setAllowSubstitution] = useState<boolean>(true);
  const [orderItems, setOrderItems] = useState<any>({});
  const [activeItem, setActiveItem] = useState<any>(null);
  const [service, setService] = useState<any>(null);
  const [discountedAmount, setDiscountedAmount] = useState<number>(0);
  const [isTooltipVisible, setTooltipVisible] = useState(false);


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}services/${5}`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setService(response.data);
      } catch (error: any) {
        
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    const totalDiscountedAmount = Object.values(orderItems).reduce(
      (sum: number, item: any) => sum + ((item.discountedPrice || 0) * item.quantity),
      0
    );
    setDiscountedAmount(totalDiscountedAmount);
  }, [orderItems]);

  const handleCounterClick = (id: string) => {
    setActiveItem((prevItem: any) => (prevItem && prevItem.id === id ? prevItem : { id }));
  };

  useEffect(() => {
    const orderItemsFromStorage = localStorage.getItem('groceryItems');
    if (orderItemsFromStorage) {
      const parsedOrderItems = JSON.parse(orderItemsFromStorage);
      setOrderItems(parsedOrderItems);

      if (Object.keys(parsedOrderItems).length <= 0) {
        onBack();
      }
    } else {
      onBack();
    }
  }, []);

  useEffect(()=>{

  }, [])

  const increaseQuantity = (item: any) => {
    setOrderItems((prevOrderItems: any) => {
      const existingItem = prevOrderItems[item.id];
      let newQuantity;
      if (existingItem?.quantity === item.maxOrderQuantity) {
        newQuantity = existingItem.quantity;
      } else {
        newQuantity = existingItem ? existingItem.quantity + 1 : 1;
      }
      const updatedItems = {
        ...prevOrderItems,
        [item.id]: {
          ...item,
          quantity: newQuantity,
        },
      };
      localStorage.setItem('groceryItems', JSON.stringify(updatedItems));
      return updatedItems;
    });
  };

  const decreaseQuantity = (item: any) => {
    setOrderItems((prevOrderItems: any) => {
      const existingItem = prevOrderItems[item.id];
      if (existingItem) {
        const newQuantity = existingItem.quantity - 1;
        let updatedItems;
        if (newQuantity > 0) {
          updatedItems = {
            ...prevOrderItems,
            [item.id]: {
              ...existingItem,
              quantity: newQuantity,
            },
          };
        } else {
          updatedItems = { ...prevOrderItems };
          delete updatedItems[item.id];
        }
        localStorage.setItem('groceryItems', JSON.stringify(updatedItems));
  
        if (Object.keys(updatedItems).length <= 0) {
          onBack();
        }
  
        return updatedItems;
      }
      return prevOrderItems;
    });
  };
  


  const deliveryFee = 7.00;
  const subAmount = Object.values(orderItems)
    .reduce((sum: number, item: any) => sum + parseFloat(item.grossPrice) * item.quantity, 0);

  const totalAmount = (subAmount + deliveryFee).toFixed(2);

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div
          className={styles.backArrow}
          onClick={() => onBack()}
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
            </svg>
        </div>
      </div>
      <div className={styles.checkOut}>
        <h1>Basket</h1>
        <div className={styles.service}>
          {Object.values(orderItems).map((item: any) => (
            <div key={item.id} className={`flex ${styles.section}`}>
              <img
                src={item.imageUrls["200_200"] || "/services/groceryitem.png"}
                alt={item.name}
              />
              <div className='flex-1 flex flex-col gap-2'>
                <h2>{item.name}</h2>
                <div className="flex gap-2">
                  <p>
                    AED {(parseFloat(item.grossPrice) * item.quantity).toFixed(2)}
                  </p>
                </div>
              </div>
              <div>
                <div
                  onClick={() => handleCounterClick(item.id)}
                  className={`flex flex-col justify-between ${styles.counter} ${activeItem?.id === item.id ? styles.counterShow : ''}`}
                >
                  <button
                    disabled={!(activeItem || activeItem?.id !== item.id)}
                    onClick={() => increaseQuantity(item)}
                    className={`${styles.button} ${activeItem?.id === item.id ? styles.buttonShow : ''}`}
                  >
                    <ReactplusIcon />
                  </button>
                  <span
                    className={styles.count}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.quantity}
                  </span>
                  <button
                    disabled={!(activeItem || activeItem?.id !== item.id)}
                    onClick={() => decreaseQuantity(item)}
                    className={`${styles.button} ${activeItem?.id === item.id ? styles.buttonShow : ''}`}
                  >
                    {item.quantity > 1 ? <ReactColoredsubIcon /> : <ReacttrashIcon />}
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className={`${styles.section} ${styles.substitution} flex justify-between`}>
            <div className={`flex gap-2`}>
                <span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 21.5L16 20L17.5 18.5" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16 20H18C19.6569 20 21 18.6569 21 17" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 21H11C12.1046 21 13 20.1046 13 19V12C13 10.8954 12.1046 10 11 10H5C3.89543 10 3 10.8954 3 12V19C3 20.1046 3.89543 21 5 21Z" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.235 14H19C20.1046 14 21 13.1046 21 12V5C21 3.89543 20.1046 3 19 3H13C11.8954 3 11 3.89543 11 5V7" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6.5 2.5L8 4L6.5 5.5" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 4H6C4.34315 4 3 5.34315 3 7" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                <p>Allow substitutions</p>
                <span onClick={(e) => {
                  e.stopPropagation()
                  setTooltipVisible(true)}} className={styles.hint}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="8"
                      stroke="url(#paint0_linear_7939_96624)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.9993 8.72783C11.9012 8.72783 11.8215 8.80747 11.8223 8.9056C11.8223 9.00374 11.9019 9.08338 12 9.08338C12.0982 9.08338 12.1778 9.00374 12.1778 8.9056C12.1778 8.80747 12.0982 8.72783 11.9993 8.72783"
                      stroke="url(#paint1_linear_7939_96624)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 11.84V15.3955"
                      stroke="url(#paint2_linear_7939_96624)"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_7939_96624"
                        x1="4"
                        y1="15.3333"
                        x2="20.2536"
                        y2="13.9229"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3B51F9" />
                        <stop offset="1" stopColor="#5EBFFB" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_7939_96624"
                        x1="11.6445"
                        y1="9.05375"
                        x2="12.3669"
                        y2="8.99107"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3B51F9" />
                        <stop offset="1" stopColor="#5EBFFB" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_7939_96624"
                        x1="11.6445"
                        y1="14.3585"
                        x2="12.3721"
                        y2="14.3459"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3B51F9" />
                        <stop offset="1" stopColor="#5EBFFB" />
                      </linearGradient>
                    </defs>
                  </svg>

                  {/* Tooltip Text */}
                  {isTooltipVisible && (
                    <span className={styles.tooltiptext}>
                      <p>
                        By selecting this option, you’ll let us replace any out-of-stock
                        items with a similar alternative when preparing your order for
                        shipping.
                      </p>

                      <svg
                        onClick={(e) => {
                          e.stopPropagation()
                          setTooltipVisible(false)}} // Close the tooltip on click
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_7939_107366)">
                          <path
                            d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
                            fill="white"
                            fillOpacity="0.3"
                          />
                          <path
                            d="M10.125 5.875L5.875 10.125M5.875 5.875L10.125 10.125L5.875 5.875Z"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_7939_107366">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  )}
                </span>
            </div>
            <div className={styles.actions}>
                <label className={styles.switch}>
                    <input onClick={()=> setAllowSubstitution(!allowSubstitution)} type="checkbox" checked={allowSubstitution}/>
                    <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
            </div>
          </div>
        </div>
        <div className={styles.paymentSummary}>
          <h4>Payment summary</h4>
          <div className={`${styles.serviceDetail}`}>
            <div className={styles.amounts}>
              {Object.values(orderItems).map((item: any) => (
                <div
                  key={item.id}
                  className={`flex justify-between ${styles.subTotal}`}
                >
                  <p className="w-3/4">
                    x{item.quantity} {item.name}
                  </p>
                  <p>AED {(parseFloat(item.grossPrice) * item.quantity).toFixed(2)}</p>
                </div>
              ))}

              {discountedAmount > 0 && (
                <div
                  className={`flex justify-between ${styles.subTotal} ${styles.discountAmount}`}
                >
                  <p>Discount 30%</p>
                  <p>AED {discountedAmount.toFixed(2)}</p>
                </div>
              )}
              <hr />
              <div className={`flex justify-between ${styles.subTotal}`}>
                <p>Subtotal</p>
                <p>AED {subAmount.toFixed(2)}</p>
              </div>
              <div className={`flex justify-between ${styles.deliveryFee}`}>
                <p>Delivery fee</p>
                <p>AED {deliveryFee.toFixed(2)}</p>
              </div>
              <hr />
              <div className={`flex justify-between ${styles.total}`}>
                <p>Total amount</p>
                <p>AED {totalAmount}</p>
              </div>
              <div className={styles.extaData}>
              </div>
            </div>
          </div>
        </div>
        {service && Object.keys(orderItems).length > 0 && (
        <div className={styles.footer}>
        {(parseFloat(totalAmount) - deliveryFee) > parseFloat(service.minimumOrderAmount) ? (
          <button
          onClick={()=> onContinue(allowSubstitution)}
          className={`flex items-center justify-between ${styles.continue}`}
        >
          <div className="flex items-center gap-2">
            <span>
              {Object.values(orderItems).reduce(
                (sum: number, item: any) => sum + item.quantity,
                0
              )}
            </span>
            <p>Go to checkout</p>
          </div>
          <p>
            AED{" "}
              {totalAmount}
          </p>
        </button>
          ) : (
            <>
            <button
            onClick={()=> onBack()}
            className={`flex items-center justify-center ${styles.addMoreItems}`}>
                <p>Add more items</p>
            </button>
            <p className={styles.minimumOrder}>The minimum order for delivery is AED {service.minimumOrderAmount}</p>
            </>
          )}
        </div>
      )}
      </div>
    </div>
  );
};

export default Basket;
