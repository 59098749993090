import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from '../../styles/foodService/selectItem.module.css'
import { Link, useNavigate } from "react-router-dom";
import SectionItems from "./sectionItems";
import axios from "axios";
import { itemSkeleton, sectionsSkeleton } from "../../utils/skeleton";


type menuSection = {
  id: number;
  name: string;
};

interface SelectItemProps {
  onContinue: () => void;
}
const FoodSelectItem: React.FC<SelectItemProps> = ({ onContinue }) => {
    const navigate = useNavigate()
    const [orderItems, setOrderItems] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [isScrolled, setIsScrolled] = useState(false);
    const [menuSections, setMenuSections] = useState<menuSection[]>([])
    const menuItemRefs = useRef<(HTMLLIElement | null)[]>([]);
    const orders = localStorage.getItem("orderItems")
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(true)
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) { // Adjust the offset value as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
  
    useEffect(() => {
      const latestItem = searchParams.get('item')
      if(latestItem){
        const element = document.getElementById(`item-${latestItem}`);
        if (element) {
          element.scrollIntoView({block: "center"});
          searchParams.delete("item")
          window.history.replaceState({}, '', `${url.pathname}?${searchParams.toString()}`);
        }

      }
    },[menuSections])

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

    }, []);

    useEffect(()=>{
      if(orders){
          const parsedOrders = JSON.parse(orders)
          setOrderItems(parsedOrders)
      }
    },[orders])


    useEffect(() => {
      const fetchServices = async () => {
        try {
          const token = localStorage.getItem("PropertyToken");
          if (!token) {
            throw new Error("PropertyToken not found");
          }
  
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}services/food/menu`,
            {
              headers: {
                propertyToken: token,
              },
            }
          );
          setLoading(false)
          setMenuSections(response.data);
        } catch (error) {
          setMenuSections([])
        } finally {
        }
      };
  
      fetchServices();
    }, []);
    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };

    const handleItemClick = (index: number) => {
      const section = document.getElementById(`section-${index}`);
      
      if (section) {
        const offset = activeIndex === 0 && window.scrollY < 50 ? 350 : 150;
        const sectionTop = section.offsetTop - offset;
        
        window.scrollTo({
          top: sectionTop,
          behavior: 'smooth'
        });
      }
      
      setDropdownVisible(false);
    };
  
    useLayoutEffect(() => {
      if (menuItemRefs.current[activeIndex]) {
        const menuItem = menuItemRefs.current[activeIndex];
        if (menuItem) {
          const parent = menuItem.parentElement;
          if (parent) {
            const parentRect = parent.getBoundingClientRect();
            const childRect = menuItem.getBoundingClientRect();
            const scrollOffset = childRect.left - parentRect.left - parentRect.width / 2 + childRect.width / 2;
  
            parent.scrollTo({
              left: parent.scrollLeft + scrollOffset,
              behavior: 'smooth'
            });
          }
        }
      }
    }, [activeIndex]);

  return (
    <div className={styles.foodSelectItems}>
     <header className={`${styles.header} ${isScrolled ? styles.scrolled : ''}`}>
        <div className={`flex ${styles.upper} place-content-between items-center `}>
          <span onClick={() => { navigate("/app") }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
            </svg>
          </span>
        </div>
        <div className={styles.title}>
          <h1>Select your food</h1>
          {!isScrolled && <p>Aprox delivery 30 - 45 min</p>}
        </div>
        <div></div>
      </header>
          {loading ? (
           <>
            <div className={`${styles.menuSections}`}>
              {sectionsSkeleton()}
              </div>
              <div className="ml-2 flex flex-col gap-5">
                {[1, 2, 3, 4].map((_, index) => (
                  <div key={index}>
                    {itemSkeleton()}
                  </div>
                ))}
              </div>
            </>
          ):(
          <>
            <div className={`${styles.menuSections} ${isScrolled ? styles.scrolledMenu : ''}`}>
              <ul className={`${styles.sectionsHeader} flex items-center`}>
                <div className={styles.sectionDrop}>
                  <svg onClick={toggleDropdown} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="37" height="37" rx="18.5" fill="white" />
                    <rect x="0.5" y="0.5" width="37" height="37" rx="18.5" stroke="#E4E8EC" />
                    <path d="M16.7 14H25.7" stroke="#323E48" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M16.7 19H25.7" stroke="#323E48" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M16.7 24H25.7" stroke="#323E48" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M12.3 14H13.3" stroke="#323E48" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M12.3 19H13.3" stroke="#323E48" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M12.3 24H13.3" stroke="#323E48" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </div>
                {menuSections.map((section, index) => (
                  <li
                    key={index}
                    className={index === activeIndex ? styles.active : ''}
                    ref={(el) => (menuItemRefs.current[index] = el)}
                    onClick={() => handleItemClick(index)}
                  >
                    {section.name}
                  </li>
                ))}
              </ul>
              {dropdownVisible && <div onClick={() => setDropdownVisible(false)} className={styles.overlay}></div>}
              {dropdownVisible &&
                <ul className={styles.sectionDropDown}>
                  {menuSections.map((item: any, index: number) => (
                        <li
                          key={index}
                          className={` ${styles.dropdownItem} ${index === activeIndex ? styles.active : ''}`}
                          onClick={() => handleItemClick(index)}
                        >
                          {item.name}
                        </li>
                      ))}
                </ul> 
              }
            </div>
            <div className={styles.sectionItems}>
              {menuSections.map((section, index) => (
                <SectionItems
                  key={index}
                  id={`section-${index}`}
                  index={index}
                  section={section}
                  setActiveIndex={setActiveIndex}
                />
              ))}
            </div>
          </>
          )}

      {orderItems && orderItems.length > 0 &&
        <div className={` ${styles.footer}`}>
            <button onClick={()=> onContinue()} className={`flex items-center justify-between ${styles.continue}`}>
                  <div className="flex items-center gap-2">
                    <span>{orderItems.reduce((sum:number, item:any) => sum + item.quantity, 0)}</span>
                    <p>Go to basket</p>
                  </div>
                  <p>AED {orderItems.reduce((sum:number, item:any) => sum + item.totalPrice, 0).toFixed(2)}</p>              

            </button>
        </div>
      }
    </div>
  );
};


export default FoodSelectItem;
