import React, { useEffect, useState } from "react";
import styles from "../../styles/success.module.css";
import { useNavigate } from "react-router-dom";


interface SuccessProps {
  items: any;
}

const Success: React.FC<SuccessProps> = ({
  items,
}) => {
  const navigate = useNavigate();
  console.log(items);

  return (
    <>
      <span>
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3.83325" y="3.33334" width="33.3333" height="33.3333" rx="16.6667" stroke="url(#paint0_linear_5574_48097)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.5691 20.5496L18.1808 24.1613L18.1574 24.1379L26.3058 15.9896" stroke="url(#paint1_linear_5574_48097)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              <defs>
              <linearGradient id="paint0_linear_5574_48097" x1="3.83325" y1="26.9445" x2="37.695" y2="24.0061" gradientUnits="userSpaceOnUse">
              <stop stop-color="#3B51F9"/>
              <stop offset="1" stop-color="#5EBFFB"/>
              </linearGradient>
              <linearGradient id="paint1_linear_5574_48097" x1="14.5691" y1="21.7779" x2="26.3979" y2="20.3036" gradientUnits="userSpaceOnUse">
              <stop stop-color="#3B51F9"/>
              <stop offset="1" stop-color="#5EBFFB"/>
              </linearGradient>
              </defs>
          </svg>
      </span>
      <div className={`flex flex-col ${styles.successData}`}>
        <h4 className="text-center">Food menu request confirmed</h4>
        <div className={` ${styles.details}`}>
          <div>
            {Object.values(items).map((item: any) => (
                <div className={` flex gap-2 ${styles.section}`}>
                  <img
                      src={item.imageUrls["200_200"] || "/services/groceryitem.png"}
                      alt=""
                  />
                  <div className='flex-1 flex flex-col gap-2'>
                      <h2>{item.name}</h2>
                      <p>
                            AED {(item.grossPrice * item.quantity).toFixed(2)}
                      </p>
                  </div>
                  <div>
                      <span>
                        {item.quantity}
                      </span>
                    </div>
                </div>
              ))}
          </div>
          <div className={styles.dateTime}>
            <div className={`flex mt-4 items-center ${styles.detailsItem}`}>
              <span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.2619 20.7738C16.9629 20.7738 20.7738 16.9629 20.7738 12.2619C20.7738 7.56091 16.9629 3.75 12.2619 3.75C7.56091 3.75 3.75 7.56091 3.75 12.2619C3.75 16.9629 7.56091 20.7738 12.2619 20.7738Z" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M12.2618 8.98816V12.262L15.588 16.1382" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
              <div>
                <p>Delivery in 30 - 45 min</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <button
        onClick={() => navigate("/app")}
        type="submit"
        className={styles.done}
      >
        Done
      </button>
    </>
  );
};

export default Success;
