import React, { useEffect, useState } from "react";
import styles from "../../styles/groceryService/categoryItems.module.css";
import { useNavigate, useParams } from "react-router-dom";
import SubCategories from "../../components/groceryService/subCategories";
import Items from "../../components/groceryService/items";
import axios from "axios";
import { GroceryCategory } from "../../utils/types/services";
import SearchItems from "../../components/groceryService/searchItems";

const CategoryItems: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [searchValue, setSearchValue] = useState(''); 
  const [isValidSearch, setIsValidSearch] = useState(true);
  const [category, setCategory] = useState<GroceryCategory | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedcategory, setSelectedcategory] = useState<{subcategoryId: number, isParent: boolean} | null>(null);
  const [isSearching, setIseSearching] = useState<boolean>(false)
  const [newEntry, setNewEntry] = useState<boolean>(false)
  const handleSubcategorySelect = (subcategoryId: number, isParent:  boolean) => {
    setSelectedcategory({subcategoryId, isParent});
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}services/groceries/categories/${id}`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setLoading(false);
        setCategory(response.data);
      } catch (error) {
      } finally {
      }
    };

    fetchServices();
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const trimmedValue = value.trim();

    if (trimmedValue.length < 2 || trimmedValue.length > 50) {
      setIsValidSearch(false);
    } else {
      setIsValidSearch(true);
    }

    setSearchValue(value);
  };

  return (
    <div className={styles.grocerySelectCategory}>
      {isSearching ? (
        <>
          <div onClick={() => setIseSearching(false)} className={styles.arrowBack}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
            </svg>
          </div>
          <div className={styles.searchContainer}>
            <div className={styles.search}>
              <input
                type="text"
                maxLength={50}
                autoFocus
                placeholder={`Search in ${category && category.name}`}
                value={searchValue}
                onChange={handleSearch}
              />
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.2395"
                    cy="11.2396"
                    r="5.64955"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.3953 18.3952L15.234 15.2339"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            {searchValue !== "" && isValidSearch &&
              <SearchItems categoryProps={selectedcategory} onChange={()=> setNewEntry(!newEntry)} isValidSearch={isValidSearch} searchQuery={searchValue} />
            }
          </div>
          </>
      ):(
        <>
          <header className={`${styles.header} ${styles.scrolled}`}>
            <div className={`flex ${styles.upper} place-content-between items-center `}>
              <span  onClick={() => { navigate("/app/groceries/categories") }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
                </svg>
              </span>
            </div>
            <div className={styles.title}>
              <h1>{category && category.name}</h1>
            </div>
            <div onClick={() => setIseSearching(true)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="11.1128" cy="11.1129" rx="6.59115" ry="6.59114" stroke="#323E48" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.4612 19.4612L15.7729 15.7729" stroke="#323E48" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </header>
          <div className={styles.subCategories}>
            <SubCategories onCategorySelect={handleSubcategorySelect} />
          </div>
          <div className={styles.items}>
            <Items categoryProps={selectedcategory} />
          </div>
        </>
      )}
      </div>
  );
};

export default CategoryItems;
