import React, { useEffect, useRef, useState } from "react";
import styles from "../../styles/groceryService/items.module.css";
import ItemModal from "./itemModal";
import axios from "axios";
import { ReactComponent as ReactplusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as ReactColoredsubIcon } from '../../assets/icons/coloredSub.svg';
import { ReactComponent as ReacttrashIcon } from '../../assets/icons/trash.svg';
import EmptyGroceryItems from "./emptyGroceryItems";
import { centeredImgTwotext } from "../../utils/skeleton";

interface ItemsProps {
  searchQuery: string;
  isValidSearch: boolean;
  onChange: () => void;
  categoryProps?: { subcategoryId: number; isParent: boolean } | null;
}

const SearchItems: React.FC<ItemsProps> = ({
  searchQuery,
  isValidSearch,
  onChange,
  categoryProps,
}) => {
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);
  const [orderItems, setOrderItems] = useState<{
    [key: number]: { quantity: number; data: any };
  }>({});
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [items, setItems] = useState<any[]>([]);
  const [openedItems, setOpenedItems] = useState<{ [key: number]: boolean }>(
    {}
  );
  const timersRef = useRef<{ [key: number]: NodeJS.Timeout }>({});

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);

  // Reset pagination and items when search query changes
  useEffect(() => {
    setPage(1);
    setTotalPages(null);
    setItems([]);
  }, [searchQuery, categoryProps]);

  // Fetch items when search query, category, or page changes
  useEffect(() => {
    if (isValidSearch) {
      const fetchItems = async () => {
        if (page === 1) {
          setInitialLoading(true);
        } else {
          setIsFetching(true);
        }
        try {
          const token = localStorage.getItem("PropertyToken");
          if (!token) {
            throw new Error("PropertyToken not found");
          }
          const limit = 10; // Default limit
          let response: any;
          const urlBase =
            categoryProps && categoryProps.isParent
              ? `${process.env.REACT_APP_BACKEND_URL}services/groceries/categories/${categoryProps.subcategoryId}/items`
              : `${process.env.REACT_APP_BACKEND_URL}services/groceries/categories/items`;

          response = await axios.get(urlBase, {
            params: {
              search: searchQuery,
              page,
              limit,
            },
            headers: {
              propertyToken: token,
            },
          });

          setItems((prevItems) => [...prevItems, ...response.data.data]);
          setTotalPages(response.data.totalPages);
          setRequestSent(true);
          if (page === 1) {
            setInitialLoading(false);
          } else {
            setIsFetching(false);
          }
        } catch (error) {
          console.error("Error fetching items:", error);
          setItems([]);
          if (page === 1) {
            setInitialLoading(false);
          } else {
            setIsFetching(false);
          }
        }
      };

      // Debounce the API call
      const timeoutId = setTimeout(() => {
        fetchItems();
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      setItems([]);
    }
  }, [searchQuery, isValidSearch, page, categoryProps]);

  // Infinite scroll implementation
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 500 &&
        !isFetching &&
        (totalPages === null || page < totalPages)
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching, page, totalPages]);

  const openModal = (item: any) => {
    setSelectedItem(item);
    setModalIsOpen(true);
    setShouldAnimateOut(false);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItem(null);
  };

  useEffect(() => {
    if (shouldAnimateOut) {
      const timer = setTimeout(() => {
        setModalIsOpen(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [shouldAnimateOut]);

  useEffect(() => {
    const groceries = localStorage.getItem("groceryItems");
    if (groceries) {
      const parsedOrders = JSON.parse(groceries);
      setOrderItems(parsedOrders);
    }
  }, [modalIsOpen]);

  useEffect(() => {
    localStorage.setItem("groceryItems", JSON.stringify(orderItems));
    onChange();
  }, [orderItems]);

  const increment = (item: any) => {
    setOrderItems((prevOrderItems) => {
      const existingItem = prevOrderItems[item.id];
      let newQuantity;
      if (existingItem?.quantity === item.maxOrderQuantity) {
        newQuantity = existingItem.quantity;
      } else {
        newQuantity = existingItem ? existingItem.quantity + 1 : 1;
      }
      return {
        ...prevOrderItems,
        [item.id]: {
          ...item,
          quantity: newQuantity,
        },
      };
    });

    setOpenedItems((prevOpenedItems) => ({
      ...prevOpenedItems,
      [item.id]: true,
    }));

    if (timersRef.current[item.id]) {
      clearTimeout(timersRef.current[item.id]);
    }

    timersRef.current[item.id] = setTimeout(() => {
      setOpenedItems((prevOpenedItems) => ({
        ...prevOpenedItems,
        [item.id]: false,
      }));
      delete timersRef.current[item.id];
    }, 2000);
  };

  const decrement = (item: any) => {
    setOrderItems((prevOrderItems) => {
      const existingItem = prevOrderItems[item.id];
      if (existingItem) {
        const newQuantity = existingItem.quantity - 1;
        if (newQuantity > 0) {
          return {
            ...prevOrderItems,
            [item.id]: {
              ...existingItem,
              quantity: newQuantity,
            },
          };
        } else {
          const newOrderItems = { ...prevOrderItems };
          delete newOrderItems[item.id];

          setOpenedItems((prevOpenedItems) => {
            const newOpenedItems = { ...prevOpenedItems };
            delete newOpenedItems[item.id];
            return newOpenedItems;
          });

          if (timersRef.current[item.id]) {
            clearTimeout(timersRef.current[item.id]);
            delete timersRef.current[item.id];
          }

          return newOrderItems;
        }
      }
      return prevOrderItems;
    });

    setOpenedItems((prevOpenedItems) => ({
      ...prevOpenedItems,
      [item.id]: true,
    }));

    if (timersRef.current[item.id]) {
      clearTimeout(timersRef.current[item.id]);
    }

    timersRef.current[item.id] = setTimeout(() => {
      setOpenedItems((prevOpenedItems) => ({
        ...prevOpenedItems,
        [item.id]: false,
      }));
      delete timersRef.current[item.id];
    }, 2000);
  };

  const openCounter = (itemId: number) => {
    setOpenedItems((prevOpenedItems) => ({
      ...prevOpenedItems,
      [itemId]: true,
    }));

    if (timersRef.current[itemId]) {
      clearTimeout(timersRef.current[itemId]);
    }

    timersRef.current[itemId] = setTimeout(() => {
      setOpenedItems((prevOpenedItems) => ({
        ...prevOpenedItems,
        [itemId]: false,
      }));
      delete timersRef.current[itemId];
    }, 2000);
  };

  useEffect(() => {
    return () => {
      Object.values(timersRef.current).forEach((timer) => clearTimeout(timer));
    };
  }, []);

  if (requestSent && items.length === 0)
    return <EmptyGroceryItems searchQuery={searchQuery} />;

  return (
    <>
      <div className={styles.searchItems}>
        {initialLoading ? (
          <>
            {[1, 2, 3, 4, 5, 6].map((_, index) => (
              <div className={styles.item} key={index}>
                {centeredImgTwotext()}
              </div>
            ))}
          </>
        ) : (
          <>
            {items.map((item) => (
              <div
                key={item.id}
                className={styles.item}
                onClick={() => openModal(item)}
              >
                <div className={styles.imageContainer}>
                  <img
                    src={item.imageUrls["200_200"] || "/services/groceryitem.png"}
                    alt={item.name}
                  />
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className={styles.addGroceries}
                  >
                    <div
                      className={`${styles.container} ${
                        orderItems[item.id]
                          ? openedItems[item.id]
                            ? styles.opened
                            : styles.collapsed
                          : ""
                      }`}
                    >
                      {/* Plus Button */}
                      <button
                        className={`${styles.plusButton} ${
                          orderItems[item.id] ? styles.hidden : ""
                        }`}
                        onClick={() => increment(item)}
                      >
                        <ReactplusIcon />
                      </button>

                      {/* Counter */}
                      {orderItems[item.id]?.quantity > 0 && (
                        <div className={styles.counter}>
                          {openedItems[item.id] && (
                            <button
                              className={styles.button}
                              onClick={() => decrement(item)}
                              disabled={orderItems[item.id]?.quantity === 0}
                            >
                              {orderItems[item.id]?.quantity === 1 ? (
                                <ReacttrashIcon />
                              ) : orderItems[item.id]?.quantity > 1 ? (
                                <ReactColoredsubIcon />
                              ) : (
                                ""
                              )}
                            </button>
                          )}
                          <span
                            className={styles.count}
                            onClick={() => openCounter(item.id)}
                          >
                            {orderItems[item.id]?.quantity}
                          </span>
                          {openedItems[item.id] && (
                            <button
                              disabled={
                                orderItems[item.id]?.quantity ===
                                item.maxOrderQuantity
                              }
                              className={styles.button}
                              onClick={() => increment(item)}
                            >
                              <ReactplusIcon />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.info}>
                  <p className={styles.price}>AED {item.grossPrice}</p>
                  <p className={styles.name}>{item.name}</p>
                </div>
              </div>
            ))}
            {isFetching && (
              <div className={styles.loadingMore}>
                {/* Loading spinner or message */}
                Loading more items...
              </div>
            )}
          </>
        )}
      </div>
      {modalIsOpen && selectedItem && (
        <ItemModal
          shouldAnimateOut={shouldAnimateOut}
          item={selectedItem}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
      )}
    </>
  );
};

export default SearchItems;
