export const generateTimes = () => {
    const times = [];
    for (let hour = 8; hour < 19; hour++) {
      for (let minute of [0]) {
        const hour12 = hour % 12 || 12;
        if (hour === 18 && minute !== 0) return times;
        const minuteString = minute === 0 ? "00" : `${minute}`;
        const period = hour < 12 ? "AM" : "PM";
        const startTimeString = `${hour12}:${minuteString} ${period}`;
  
        const endHour = hour + 2;
        const endHour12 = endHour % 12 || 12;
        const endPeriod = endHour < 12 ? "AM" : "PM";
        const endTimeString = `${endHour12}:${minuteString} ${endPeriod}`;
  
        times.push(`${startTimeString} - ${endTimeString}`);
      }
    }
    return times;
  };


  export const getNearestUpcomingTimeSlot = (
    currentHour: number,
    currentMinute: number
  ) => {
    const times = generateTimes();
    const currentTimeInMinutes = currentHour * 60 + currentMinute;
    const nearestTimeSlotIndex = times.findIndex((time) => {
      const [hourMinute, period] = time.split(" ");
      let [hour, minute] = hourMinute.split(":").map(Number);
      if (period === "PM" && hour !== 12) hour += 12;
      if (period === "AM" && hour === 12) hour = 0;
      const timeInMinutes = hour * 60 + minute;
      return (
        timeInMinutes >= currentTimeInMinutes + 180 && timeInMinutes <= 18 * 60
      );
    });
    return times.slice(nearestTimeSlotIndex);
  };


  export const generateDates = (today: Date) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
      const options: any = { weekday: "long", day: "numeric", month: "long" };

      let dateString;
      const currentHour = today.getHours();
      if (i === 0 && currentHour > 16) continue;
      
      if (i === 0 && currentHour < 16) {
        const dayMonth = futureDate.toLocaleDateString("en-US", {
          day: "numeric",
          month: "long",
        });
        dateString = `Today, ${dayMonth}`;
      } else {
        dateString = futureDate.toLocaleDateString("en-US", options);
      }
      dates.push(dateString);
    }
    return dates;
  };