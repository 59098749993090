import React from 'react';
import styles from '../../styles/success.module.css'
import { useNavigate } from 'react-router-dom';



const Success: React.FC = () => {
  const navigate = useNavigate()
  
  return (
    <>
        <span>
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3.83325" y="3.33334" width="33.3333" height="33.3333" rx="16.6667" stroke="url(#paint0_linear_5574_48097)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.5691 20.5496L18.1808 24.1613L18.1574 24.1379L26.3058 15.9896" stroke="url(#paint1_linear_5574_48097)" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
            <linearGradient id="paint0_linear_5574_48097" x1="3.83325" y1="26.9445" x2="37.695" y2="24.0061" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3B51F9"/>
            <stop offset="1" stop-color="#5EBFFB"/>
            </linearGradient>
            <linearGradient id="paint1_linear_5574_48097" x1="14.5691" y1="21.7779" x2="26.3979" y2="20.3036" gradientUnits="userSpaceOnUse">
            <stop stop-color="#3B51F9"/>
            <stop offset="1" stop-color="#5EBFFB"/>
            </linearGradient>
            </defs>
          </svg>

        </span>
        <div className={`flex flex-col ${styles.successData}`}>
          <h4 className='text-center'>Fresh towels request confirmed</h4>
          <div className={` ${styles.details}`}>
                <div className={`flex ${styles.info}`}>
                  <span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5843 20.0033H4.3341C3.8138 20.0033 3.38036 19.6045 3.33715 19.086L2.50346 9.08182C2.48022 8.80302 2.5748 8.52722 2.76423 8.32135C2.95367 8.11547 3.22065 7.99832 3.50042 7.99832H15.0051" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12.5041 7.99833V6.93209C12.5041 4.99829 10.9364 3.43063 9.00262 3.43063V3.43063C7.06882 3.43063 5.50116 4.99829 5.50116 6.93209V7.99833" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.7866 15.0013H14.225C13.2712 15.0013 12.45 15.6745 12.263 16.6097L11.5235 20.3073C11.4647 20.6012 11.5408 20.906 11.7308 21.1378C11.9208 21.3696 12.2047 21.504 12.5044 21.504H20.5071C20.8068 21.504 21.0907 21.3696 21.2808 21.1378C21.4708 20.906 21.5469 20.6012 21.4881 20.3073L20.7485 16.6097C20.5615 15.6745 19.7403 15.0013 18.7866 15.0013Z" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.5058 2.49603V2.49603C17.3345 2.49603 18.0064 3.16789 18.0064 3.99666V14.501C18.0064 14.7773 17.7824 15.0012 17.5062 15.0012H15.5053C15.2291 15.0012 15.0051 14.7773 15.0051 14.501V3.99666C15.0051 3.16789 15.677 2.49603 16.5058 2.49603Z" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M21.0271 18.0025H11.9844" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </span>
                  <div>
                    <p>Fresh towel delivery</p>
                  </div>
                </div>
                <div className={styles.dateTime}>
                    <div className={`flex ${styles.detailsItem}`}>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.2619 20.7738C16.9629 20.7738 20.7738 16.9629 20.7738 12.2619C20.7738 7.56091 16.9629 3.75 12.2619 3.75C7.56091 3.75 3.75 7.56091 3.75 12.2619C3.75 16.9629 7.56091 20.7738 12.2619 20.7738Z" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12.2618 8.98816V12.262L15.588 16.1382" stroke="#8B98A4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                        <p>Delivery in 30 min</p>
                    </div>
                </div>
              </div>
          </div>
        <button onClick={()=> navigate('/app')} type="submit" className={styles.done}>Done</button>
    </>
  );
};

export default Success;
