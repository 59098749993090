export const convertDateTimeBack = (formattedDate: string, formattedTime: string) => {
    const dateParts = formattedDate.split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is zero-indexed
    const day = parseInt(dateParts[2], 10);
    const dateObj = new Date(year, month, day);
  
    let dateString;
    const today = new Date();
    if (dateObj.toDateString() === today.toDateString()) {
      const options: any = {day: 'numeric', month: 'short' };
      dateString = `Today, ${dateObj.toLocaleDateString('en-US', options)}`;
    } else {
      const options: any = { weekday: 'short', day: 'numeric', month: 'short' };
      dateString = dateObj.toLocaleDateString('en-US', options);
    }
  
    const timeParts = formattedTime.split(':');
    let hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
  
    let period = 'AM';
    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    }
    if (hours === 0) {
      hours = 12;
    }
  
    const timeStr = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  
    return { dateString, timeStr };
  };



  export const formatCreatedDate = (dateString: string) => {
    const date = new Date(dateString);
  
    const options: any = { 
      weekday: 'short', 
      day: '2-digit', 
      month: 'short', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true 
    };
  
    const formattedDate = date.toLocaleDateString('en-US', options);
    
    const today = new Date();
    const isToday = date.getDate() === today.getDate() &&
                    date.getMonth() === today.getMonth() &&
                    date.getFullYear() === today.getFullYear();
  
    return isToday ? formattedDate.replace(/^\w+/, 'Today') : formattedDate;
  }


  export const convertDateOnly = (date: string) => {    

    const today = new Date();
    let datePart;

    if (date.includes('Today')) {
      datePart = today;
    } else {
        const year = today.getFullYear();
        datePart = new Date(`${date.replace('Today, ', '')} ${year}`);
    }
    const year = datePart.getFullYear();
    const month = String(datePart.getMonth() + 1).padStart(2, '0');
    const day = String(datePart.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate
  };


  export const convertDateBackOnly = (date: string) => {    
    const dateParts = date.split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is zero-indexed
    const day = parseInt(dateParts[2], 10);
    const dateObj = new Date(year, month, day);
  
    let dateString;
    const today = new Date();
    if (dateObj.toDateString() === today.toDateString()) {
      const options: any = {day: 'numeric', month: 'short' };
      dateString = `Today, ${dateObj.toLocaleDateString('en-US', options)}`;
    } else {
      const options: any = { weekday: 'short', day: 'numeric', month: 'short' };
      dateString = dateObj.toLocaleDateString('en-US', options);
    }

    return dateString
  };



  export const formatFoodTime = (dateString: string, delivery: number) => {
    const date = new Date(dateString);
  
    date.setMinutes(date.getMinutes() + delivery);
  
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    const formattedDate = date.toLocaleTimeString('en-US', options);
    
    return formattedDate;
  };
  