import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import notify from "../components/Errortoast";
import { validateToken } from "../utils/validateToken";
import Loader from "../components/Loader";

interface handlePropertyTokenProps {
  element: React.ReactElement;
}

const HandlePropertyToken: React.FC<handlePropertyTokenProps> = ({ element }) => {
  const [isValidating, setIsValidating] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    const validateAndSetToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('property');

      if (token) {
        const {isValid, property}= await validateToken(token);
        if (isValid) {
          localStorage.setItem('PropertyToken', token);
          localStorage.setItem('Property', JSON.stringify(property));

          setIsTokenValid(true);
        } else {
          notify({ type: 'error', content: 'Property is incorrect!' });
          setIsTokenValid(false);
        }
      } else {
        const storedToken = localStorage.getItem('PropertyToken');
        if (storedToken) {
          const {isValid, property} = await validateToken(storedToken);
          if (isValid) {
            setIsTokenValid(true);
            localStorage.setItem('Property', JSON.stringify(property));
          } else {
            notify({ type: 'error', content: 'Property is incorrect!' });
            setIsTokenValid(false);
          }
        } else {
          notify({ type: 'error', content: 'Property is incorrect!' });
          setIsTokenValid(false);
        }
      }

      setIsValidating(false);
    };

    validateAndSetToken();
  }, []);

  if (isValidating) {
    return <Loader />;
  }

  if (!isTokenValid) {
    return <Navigate to="/" replace />;
  }

  return element
};

export default HandlePropertyToken;
