import { Avatar, Skeleton, Typography } from "@mui/material";


export const imgTwoTexts = () => {
    return (
      <div className="flex mt-5">
        <Skeleton
          className="mr-5"
          variant="rectangular"
          style={{borderRadius: "12px"}}
          width={50}
          height={50}
        />
        <div>
          <Typography width={200} variant="h5">
            <Skeleton />
          </Typography>
          <Typography width={200}>
            <Skeleton />
          </Typography>
        </div>
      </div>
    )
  };

  export const lightImgTwoTexts = () => {
    return (
      <div className="flex">
        <Skeleton
          className="mr-5"
          variant="rectangular"
          style={{borderRadius: "12px", backgroundColor: "var(--Neutral-300, #8B98A4)"}}
          width={50}
          height={50}
        />
        <div>
          <Typography width={150} variant="h5">
            <Skeleton style={{backgroundColor: "var(--Neutral-300, #8B98A4)"}} />
          </Typography>
          <Typography width={200}>
            <Skeleton style={{backgroundColor: "var(--Neutral-300, #8B98A4)"}} />
          </Typography>
        </div>
      </div>
    )
  };


export const threeRowTexts = () => {
    return (<div>
            <Typography width={50}>
            <Skeleton />
            </Typography>
            <Typography width={110}>
            <Skeleton />
            </Typography>
            <Typography width={150}>
            <Skeleton />
            </Typography>
        </div>)
};


export const twoRowTexts = () => {
    return (<div>
            <Typography width={150}>
            <Skeleton />
            </Typography>
            <Typography width={50}>
                <Skeleton />
            </Typography>
        </div>)
};

export const twoRowTexts2 = () => {
  return (<div>
          <Typography width={50}>
          <Skeleton />
          </Typography>
          <Typography width={100}>
              <Skeleton />
          </Typography>
      </div>)
};


export const onlyImg = () => {
  return (
      <Skeleton
        variant="rectangular"
        style={{borderRadius: "12px"}}
        width={65}
        height={64}
      />
  )
};


export const itemSkeleton = () =>{
  return (<div className="flex">
    <Skeleton
      className="mr-5"
      variant="rectangular"
      style={{borderRadius: "12px", backgroundColor: "var(--Neutral-100, #8B98A4)"}}
      width={136}
      height={136}
    />
    <div>
      <Typography width={150} variant="h5">
        <Skeleton style={{backgroundColor: "var(--Neutral-100, #8B98A4)"}} />
      </Typography>
      <Typography width={200}>
        <Skeleton style={{backgroundColor: "var(--Neutral-100, #8B98A4)"}} />
      </Typography>
      <br/>
      <Typography width={100}>
        <Skeleton style={{backgroundColor: "var(--Neutral-100, #8B98A4)"}} />
      </Typography>
    </div>
  </div>)
}

export const sectionsSkeleton = () => {
  return (
      <Typography className="flex justify-between" width="100%">
      <Skeleton width={80} height={50} style={{backgroundColor: "var(--Neutral-100, #8B98A4)"}} />
      <Skeleton width={80} height={50} style={{backgroundColor: "var(--Neutral-100, #8B98A4)"}} />
      <Skeleton width={80} height={50} style={{backgroundColor: "var(--Neutral-100, #8B98A4)"}} />
      <Skeleton width={80} height={50} style={{backgroundColor: "var(--Neutral-100, #8B98A4)"}} />
      </Typography>
  )
}


export const centeredImgOnetext = () => {
  return (
    <div className="flex flex-col items-center">
      <Skeleton
        variant="rectangular"
        style={{borderRadius: "12px"}}
        width={107}
        height={110}
      />
      <div>
        <Typography mt={2} width={100}>
          <Skeleton />
        </Typography>
      </div>
    </div>
  )
};

export const centeredImgTwotext = () => {
  return (
    <div className="flex flex-col items-center">
      <Skeleton
        variant="rectangular"
        style={{borderTopLeftRadius: "12px", borderTopRightRadius: "12px"}}
        width={"100%"}
        height={160}
      />
      <div className="self-start ml-3 mb-3">
        <Typography mt={2} width={25}>
          <Skeleton />
        </Typography>
        <Typography width={100}>
          <Skeleton />
        </Typography>
      </div>
    </div>
  )
};