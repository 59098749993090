import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import styles from "../../styles/laundryService/payment.module.css";
import axios from "axios";
import PaymentDropdown from "../PaymentDropdown";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PhoneModal from "../phoneModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LaundryPaymentCard from "./stripePayment";
import { imgTwoTexts } from "../../utils/skeleton";
import notify from "../Errortoast";

export type DropoffTime = {
  createdAt: string;
  extraFee: string;
  id: number;
  slot: string;
  type: string;
  updatedAt: string;
};

interface PaymentProps {
  pickupData: { pickupDate: string; pickupTime: string };
  dropoffData: { dropOffDate: string; dropOffTime: DropoffTime };
  onBack: () => void
}

const LaundryPayment: React.FC<PaymentProps> = ({
  pickupData,
  dropoffData,
  onBack
}) => {
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const [checkoutData, setCheckoutData] = useState<any>(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const phoneNumber: string | null = localStorage.getItem("phoneNumber");
  const parsedPhoneNumber = phoneNumber ? JSON.parse(phoneNumber) : null;  const [groupedData, setGroupedData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    setShouldAnimateOut(false);
  };

  const closeModal = () => {
    setShouldAnimateOut(true);
  };

  useEffect(() => {
    if (shouldAnimateOut) {
      const timer = setTimeout(() => {
        setModalIsOpen(false);
      }, 300); // Match the duration of the slide-out animation
      return () => clearTimeout(timer);
    }
  }, [shouldAnimateOut]);


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        const counts = localStorage.getItem("counts");
        if (!token) {
          throw new Error("PropertyToken not found");
        }
        if (!counts || JSON.stringify(counts) === "{}") return navigate(0);

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}services/laundry/checkout`,
          {
            pickupData: pickupData,
            dropoffData: dropoffData,
            items: JSON.parse(counts),
          },
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setGroupedData(groupItemsBySection(response.data.itemsData))
        setCheckoutData(response.data);
      } catch (error) {
        notify({ type: "error", content: `Something went wrong, please try again!` });
        localStorage.removeItem("counts");
        window.location.reload()
      }finally{
        setLoading(false)
      }
    };

    fetchServices();
  }, []);

  const handlePaymentMethodChange = (method: string, label: string) => {
    setSelectedMethod(method);
    setSelectedLabel(label);
  };

  const groupItemsBySection = (items: any) => {
    return items.reduce((acc: any, item: any) => {
      const sectionName = item.laundrySection.name;
      const sectionImage = item.laundrySection.image;
      if (!acc[sectionName]) {
        acc[sectionName] = {
          image: sectionImage,
          items: [],
          totalAmount: 0,
        };
      }
      acc[sectionName].items.push(item);
      acc[sectionName].totalAmount += parseFloat(item.price) * item.count;
      return acc;
    }, {});
  };

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div
          className={styles.backArrow}
          onClick={() => onBack()}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
          </svg>
        </div>
      </div>
      <div className={styles.checkOut}>
        <h1>Checkout</h1>
        <div className={styles.service}>
          {loading ? (
            <>
              {[1, 2].map((_, index) => (
                <div key={index}>
                  {imgTwoTexts()}
                </div>
              ))}
            </>
          ):(
            <>
            {groupedData &&
              Object.keys(groupedData).map((sectionName: any) => (
                <div key={sectionName} className={` flex ${styles.section}`}>
                      <img
                          src={groupedData[sectionName].image}
                          alt={sectionName}
                      />
                  <div>
                      <h2>{sectionName}</h2>
                      {groupedData[sectionName].items.map((item: any) => (
                          <div key={item.id} className={styles.item}>
                              <div className={`flex ${styles.itemData}`}>
                                  <div>
                                      <p>x{item.count} {item.name}{sectionName !== "Dry Cleaning" && `: ${item.description}`}</p>
                                  </div>
                          </div>
                      </div>
                      ))}
                      <p>
                         AED {groupedData[sectionName].totalAmount.toFixed(2)}
                      </p>
                  </div>
                </div>
              ))}
              </>
          )}
        </div>
        <div className={styles.details}>
          <h4>Details</h4>
          <div className={`${styles.serviceDetail}`}>
            <div className={`flex ${styles.detailsItem}`}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.25 19.85C12.25 19.85 5.95001 14.675 5.95001 9.94999C5.95001 6.47059 8.77061 3.64999 12.25 3.64999C15.7294 3.64999 18.55 6.47059 18.55 9.94999C18.55 14.675 12.25 19.85 12.25 19.85Z"
                    stroke="#8B98A4"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2501 12.4251V12.4251C10.8831 12.4251 9.77509 11.3171 9.77509 9.9501V9.9501C9.77509 8.58307 10.8831 7.4751 12.2501 7.4751V7.4751C13.6171 7.4751 14.7251 8.58307 14.7251 9.9501V9.9501C14.7251 11.3171 13.6171 12.4251 12.2501 12.4251Z"
                    stroke="#8B98A4"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <p>
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  `${checkoutData.departmentNumber}, ${checkoutData.buildingName}, ${checkoutData.Area.name.toLowerCase()}`
                )}
              </p>
            </div>
            <div className={`flex ${styles.detailsItem}`}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.87988 3.74512V6.49512"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.1299 3.74512V6.49512"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.75 9.70512H14.17"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.1299 9.9248V20.1348"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.1299 9.86511L22.3099 13.0451"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.13 9.86511L15.95 13.0451"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.23 20.2551H5.75C4.09 20.2551 2.75 18.9151 2.75 17.2551V8.12512C2.75 6.46512 4.09 5.12512 5.75 5.12512H16.26C17.28 5.12512 18.18 5.63512 18.72 6.41512"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div className={styles.times}>
                <p>Pick up time</p>
                <p>
                  {" "}
                  {pickupData.pickupDate}, between {pickupData.pickupTime}
                </p>
              </div>
            </div>
            <div className={`flex ${styles.detailsItem}`}>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.87988 3.74512V6.49512"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.1299 3.74512V6.49512"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.75 9.70512H14.17"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.1299 20.0752V9.86523"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.1299 20.1348L22.3099 16.9548"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19.1302 20.1348L15.9502 16.9548"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.23 20.2551H5.75C4.09 20.2551 2.75 18.9151 2.75 17.2551V8.12511C2.75 6.46511 4.09 5.12511 5.75 5.12511H16.26C17.28 5.12511 18.18 5.63511 18.72 6.41511"
                    stroke="#8B98A4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div className={styles.times}>
                <p>Dropoff time</p>
                <p>
                  {dropoffData.dropOffDate}, {dropoffData.dropOffTime.slot}
                </p>
              </div>
            </div>
            {parsedPhoneNumber &&
              <div onClick={openModal} className={`flex items-center justify-between ${styles.detailsItem}`}> 
                <div className={`flex items-center`}>
                  <span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 5V19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19V5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10 18H14" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                  <p className="ml-2">{parsedPhoneNumber.number}</p>
                </div>
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 16L15 12L11 8" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            }
          </div>
        </div>
        <div className={styles.additionalNotes}>
          <h4>Additional notes</h4>
          <div className={` flex ${styles.serviceDetail}`}>
            <input
              type="text"
              placeholder="Any additional notes"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.paymentSummary}>
          <h4>Payment summary</h4>
          <div className={` ${styles.serviceDetail}`}>
            <div className={styles.paymentMethod}>
              <PaymentDropdown
                selectedLabel={selectedLabel}
                selectedMethod={selectedMethod}
                onChange={handlePaymentMethodChange}
              />
            </div>
            <hr />
            <div className={styles.amounts}>
              {checkoutData &&
                checkoutData.itemsData.map((item: any) => (
                  <div
                    key={item.id}
                    className={` flex justify-between ${styles.subTotal}`}
                  >
                    <p>
                    x{item.count} {item.name}{item.laundrySection.name !== "Dry Cleaning" && `: ${item.description}`}
                    </p>
                    {loading ? (
                      <Skeleton
                        height={20}
                        width={100}
                        baseColor="#F2F5F8"
                        highlightColor="#fff"
                      />
                    ) : (
                      <p>AED {item.price}</p>
                    )}
                  </div>
                ))}
              <hr />
              <div className={` flex justify-between ${styles.subTotal}`}>
                <p>Subtotal</p>
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  <p>AED {checkoutData.subtotal}</p>
                )}
              </div>
              <div className={` flex justify-between ${styles.deliveryFee}`}>
                <p>Service fee</p>
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  <p>AED {checkoutData.serviceFee}</p>
                )}
              </div>
              <div className={` flex justify-between ${styles.deliveryFee}`}>
                <p>Premium time slot</p>
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  <p>AED {checkoutData.premiumTimeslot}</p>
                )}
              </div>
              <hr />
              <div className={` flex justify-between ${styles.total}`}>
                <p>Total amount</p>
                {loading ? (
                  <Skeleton
                    height={20}
                    width={100}
                    baseColor="#F2F5F8"
                    highlightColor="#fff"
                  />
                ) : (
                  <p>AED {checkoutData.total}</p>
                )}
              </div>
              <div className={styles.extaData}>

              </div>
            </div>
          </div>
        </div>
    </div>
    { checkoutData &&
                <div className={styles.footer}>
                    { (parsedPhoneNumber && checkoutData && checkoutData) ? (
                            <Elements stripe={stripePromise}>
                                <LaundryPaymentCard additionalNotes={additionalNotes} pickupData={pickupData} dropoffData={dropoffData} phoneNumber={parsedPhoneNumber} checkoutData={checkoutData} paymentMethod={selectedMethod} />
                            </Elements>
                        ) : (
                            <button onClick={openModal}>Continue</button>  
                        )
                    }
                </div>
    }
      <PhoneModal shouldAnimateOut={shouldAnimateOut} isOpen={modalIsOpen} onRequestClose={closeModal} />
    </div>
  );
};

export default LaundryPayment;
