import React, { useEffect } from 'react';
import Modal from 'react-modal';
import styles from '../styles/mobileNumber.module.css';
import MobileNumber from './MobileNumber';

interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  shouldAnimateOut: boolean
}

const PhoneModal: React.FC<CustomModalProps> = ({ isOpen, onRequestClose, shouldAnimateOut}) => {

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }

    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [isOpen]);
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      className={`${styles.phoneModal} ${shouldAnimateOut ? styles.exit : ''}`}
      overlayClassName={`${shouldAnimateOut ? styles.exitOverlay : ''}`}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }}}
      >
        <MobileNumber onProceed={onRequestClose} />
    </Modal>
  );
};

export default PhoneModal;
