import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styles from "../../styles/ navbar/navbarModal.module.css";
import { Link, useNavigate } from "react-router-dom";
import {ReactComponent as ReactInsight} from '../../assets/icons/insight.svg'

interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  shouldAnimateOut: boolean;
}

type property = {
  id: number;
  buildingName: string;
  ApartmentNumber: string;
  area: string;
};

const NavBarModal: React.FC<CustomModalProps> = ({
  isOpen,
  onRequestClose,
  shouldAnimateOut,
}) => {
  const [property, setProperty] = useState<property | null>(null);
    const navigate = useNavigate()
  useEffect(() => {
    const storedProperty = localStorage.getItem("Property");
    const parsedProperty = storedProperty ? JSON.parse(storedProperty) : null;
    setProperty(parsedProperty);
  }, []);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }
    return () => {
      document.body.classList.remove(styles.noScroll);
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      className={`${styles.navbarModal} ${shouldAnimateOut ? styles.exit : ""}`}
      overlayClassName={`${shouldAnimateOut ? styles.exitOverlay : ""}`}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
        >
        <div className={styles.container}>
            <div onClick={()=> navigate("/app/orders")} className={`flex justify-between ${styles.orders}`}>
                <div className="flex items-center gap-2">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 7.61035H8" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.13 11.6104H8" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 19.8014V4.61035C4 4.05835 4.448 3.61035 5 3.61035H19C19.552 3.61035 20 4.05835 20 4.61035V19.8014C20 20.1734 19.609 20.4144 19.276 20.2484L17.333 19.2764L14.89 20.4984C14.749 20.5684 14.584 20.5684 14.443 20.4984L12 19.2774L9.557 20.4994C9.416 20.5694 9.251 20.5694 9.11 20.4994L6.667 19.2774L4.724 20.2494C4.391 20.4144 4 20.1734 4 19.8014Z" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13 15.6104H8" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p>Your orders</p>
                </div>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 16.6104L15 12.6104L11 8.61035" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div className={styles.feedback}>
                <div className={` flex flex-col items-center justify-center ${styles.card}`}>
                    <ReactInsight />
                    <div>
                        <h5>Your ideas can enhance our service!</h5>
                        <p>From a new feature you'd like to see or simply to share your overall experience,
                            we are taking notes!
                        </p>
                    </div>
                    <Link to="https://mofbdkqh00h.typeform.com/to/nZrKW7pu" target="_blank" rel="noopener noreferrer">Share your thoughts</Link>
                    </div>
            </div>
        </div>
        <div className={`flex items-center ${styles.property}`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 19.85C12.25 19.85 5.94995 14.675 5.94995 9.95002C5.94995 6.47062 8.77055 3.65002 12.25 3.65002C15.7294 3.65002 18.55 6.47062 18.55 9.95002C18.55 14.675 12.25 19.85 12.25 19.85Z" stroke="#8B98A4" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 12.4251V12.4251C10.883 12.4251 9.77502 11.3171 9.77502 9.9501V9.9501C9.77502 8.58307 10.883 7.4751 12.25 7.4751V7.4751C13.617 7.4751 14.725 8.58307 14.725 9.9501V9.9501C14.725 11.3171 13.617 12.4251 12.25 12.4251Z" stroke="#8B98A4" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p>{property?.ApartmentNumber}, {property?.buildingName}, {property?.area.toLowerCase()}</p>

        </div>
    </Modal>
  );
};

export default NavBarModal;
