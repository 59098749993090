import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import styles from "../../styles/selectTime.module.css";
import { generateDates, generateTimes, getNearestUpcomingTimeSlot } from "../../utils/times/generateDoubleTimeDate";
import Modal from "react-modal";
import { fetchDubaiTime } from "../../utils/times/fetchDubaiTime";

interface PickupTimeProps {
  onConfirm: (pickDate: string, pickTime: string) => void;
  isOpen: boolean;
  onRequestClose: () => void;
  shouldAnimateOut: boolean
}

const PickupTimes: React.FC<PickupTimeProps> = ({ onConfirm, isOpen, onRequestClose, shouldAnimateOut }) => {
  const [dateSelected, setDateSelected] = useState("");
  const [timeSelected, setTimeSelected] = useState("");
  const [times, setTimes] = useState<any>([]);
  const navigate = useNavigate();
  const [dubaiTime, setDubaiTime] = useState<Date | null>(null);


  const handleContinueClick = () => {
    const selectedData: any = {
      pickDate: dateSelected,
      pickTime: timeSelected,
    };
    onConfirm(selectedData.pickDate, selectedData.pickTime);
    onRequestClose()
  };

  const handleDateClick = (value: string) => {
    setDateSelected(value);
  };

  const handleTimeClick = (value: string) => {
    setTimeSelected(value);
  };

  useEffect(() => {
    if(dubaiTime)
      setDateSelected(generateDates(dubaiTime)[0]);
  }, [dubaiTime]);

  useEffect(()=>{
    const fetchAndSetTime = async () => {
        const fetchedTime = await fetchDubaiTime();
        if(fetchedTime){
            const timeNow = new Date(fetchedTime) 
            setDubaiTime(timeNow)
        }
    }
    fetchAndSetTime()
},[])

  useEffect(() => {
    if(dubaiTime){
      const now = dubaiTime;
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      if (dateSelected === generateDates(dubaiTime)[0] && currentHour < 17) {
        const upcomingTimes: any = getNearestUpcomingTimeSlot(
          currentHour,
          currentMinute
        );
        setTimes(upcomingTimes);
        setTimeSelected(upcomingTimes[0]);
      } else {
        setTimeSelected(generateTimes()[0]);
        setTimes(generateTimes());
      }
    }
  }, [dateSelected, dubaiTime]);


  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    className={`${styles.modalContent} ${shouldAnimateOut ? styles.exit : ''}`}
    overlayClassName={` ${styles.modalOverlay} ${shouldAnimateOut ? styles.exitOverlay : ''}`}
    >

<div className={`flex flex-col  ${styles.container}`}>
      <h4>Select pick up date and time</h4>
        <div className={`flex ${styles.select} ${styles.modalselect}`}>
        <div className={`${styles.date}`}>
          
          {dubaiTime &&  generateDates(dubaiTime).map((date: string, index: number) => (
            <div
              key={index}
              onClick={() => handleDateClick(date)}
              className={`${styles.selectBox} ${
                dateSelected === date ? `${styles.selected}` : ""
              }`}
            >
              <p>{date.split(", ")[0]}</p>
              <p>{date.split(", ")[1]}</p>
            </div>
          ))}
        </div>
        <div className={`${styles.time}`}>
          {times.map((time: string, index: number) => (
            <div
              key={index}
              onClick={() => handleTimeClick(time)}
              className={`${styles.selectBox} ${
                timeSelected === time ? `${styles.selected}` : ""
              }`}
            >
              <p>{time}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.footer}>
        <button onClick={handleContinueClick}>Confirm</button>
      </div>
      </div>

      </Modal>
    
  );
};

export default PickupTimes;
