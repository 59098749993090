import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import styles from "../../styles/foodService/selectItem.module.css";
import { useNavigate } from "react-router-dom";

interface SelectItemProps {
  id: string;
  index: number;
  section: any;
  setActiveIndex: any;
}

const SectionItems: React.FC<SelectItemProps> = ({ id, index, section, setActiveIndex }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
    rootMargin: '-15% 0% 0% 0%',
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (inView) {
      setActiveIndex(index);
    }
  }, [inView, index, setActiveIndex, ref]);

  const handleContinue = (item: any) => {
    if(item.outOfStock || !item.timing.isOpened) return
    navigate(`menuitem/${item.id}`);
  };
  return (
    <>
      {section.menuItems.length > 0 && (
        <div id={id} ref={ref} className={styles.group}>
          <h3>{section.name}</h3>
          <div className={styles.items}>
            {section.menuItems.map((item: any) => (
              <div id={`item-${item.id}`} key={item.id} onClick={() => handleContinue(item)} className={`flex ${styles.item}`}>
                <img className={`${item.outOfStock || !item.timing.isOpened  ? styles.outOfstockImage : ''}`} src={item.imageUrls["200_200"]} alt="" />
                <div className={styles.itemData}>
                  <p className={`${styles.name} ${item.outOfStock || !item.timing.isOpened  ? styles.outOfStockText: ''}`}>{item.name}</p>
                  {item.outOfStock && 
                    <p className={styles.description}>This item is out of stock</p>
                  }
                  {!item.timing.isOpened  && 
                    <p className={styles.description}>This item is not available at this time</p>
                  }
                  {item.timing.isOpened && !item.outOfStock  && 
                    <p className={styles.description}>{item.description}</p>
                  }
                  <div className={`flex gap-2`}>
                    {item.discount > 0 &&
                      <p className={`${styles.price} ${item.outOfStock || !item.timing.isOpened  ? styles.outOfStockText: ''}`}>AED {(Math.round(item.grossPrice * (1 - item.discount) * 100) / 100).toFixed(2)}</p>
                    }
                    <p className={`${styles.totalPrice} ${item.outOfStock || !item.timing.isOpened  ? styles.outOfStockText: ''}`}>AED {item.grossPrice}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SectionItems;
