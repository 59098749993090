import axios from "axios";

export const fetchDubaiTime = async () => {
  try {
    const token = localStorage.getItem("PropertyToken");
    if (!token) {
      throw new Error("PropertyToken not found");
    }
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}dubai-timing`,
      {
        headers: {
          propertyToken: token,
        },
      }
    );
    return response.data.datetime;
  } catch (error) {
    console.error("Failed to fetch time:", error);
    return null;
  }
};
