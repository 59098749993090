import axios from "axios";

interface handlePropertyTokenProps {
  isValid: boolean,
  property?: object
}

export const validateToken = async (token: string): Promise<handlePropertyTokenProps> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}properties/validateToken`,
      {
        headers: {
          propertyToken: token,
        },
      }
    );
    if (response.status !== 200) {
      return {
        isValid: false,
      };
    }

    return { isValid: true, property: response.data };
  } catch (error) {
    console.error("Token validation failed:", error);
    return {isValid: false};
  }
};
