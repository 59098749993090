import React from "react";
import styles from '../../styles/cleaningService/info.module.css'
import'../../styles/cleaningService/info.css'
import { HowLongDoesTheServiceTake, cancellationPolicy, professionalsData, whatCleaningMaterialsAreUsed, whatDoesItInclude, whatDoesNotItInclude } from "../../data/cleaningService/faq";
import Faq from "react-faq-component";
import CheckSmall from '../../assets/CheckSmall.svg';
import { useNavigate } from "react-router-dom";
const Info: React.FC = () => {
    const navigate = useNavigate()
    const data = {
        rows: [
            {
                title: "About our professionals",
                content: <div>
                    {professionalsData.map((data, index) => (
                        <div key={index} className={`flex ${styles.dataContent}`}>
                            <img src={CheckSmall} alt="" />
                            <p className={styles.dataContentItem}>{data.content}</p>
                        </div>
                    ))}
                </div>,
            },
            {
                title: "What does it include?",
                content: <div>
                    {whatDoesItInclude.map((data, index) => (
                        <div key={index} className={`flex ${styles.dataContent}`}>
                            <img src={data.icon} alt="" />
                            <p className={styles.dataContentItem}>{data.content}</p>
                        </div>
                    ))}
                </div>,
            },
            {
                title: "What doesn’t it include?",
                content: <div>
                    {whatDoesNotItInclude.map((data, index) => (
                        <div key={index} className={`flex ${styles.dataContent}`}>
                            <img src={data.icon} alt="" />
                            <p className={styles.dataContentItem}>{data.content}</p>
                        </div>
                    ))}
                </div>,
            },
            {
                title: "How long does the service take?",
                content: <div>
                    <div className={styles.contentHeader}>
                        <p className={styles.contentHeader}>The service time will depend on the apartment size, but it normally ranges between 1-3 hours.</p>
                        <br/>
                        <p>The average time required per area per chor</p>
                    </div>
                    <div className={styles.contentTable}>
                        {HowLongDoesTheServiceTake.map((data, index) => (
                            <div key={index} className={`flex justify-between ${styles.dataContent}`}>
                                <p className={styles.tablecontent}>{data.content}</p>
                                <p className={styles.tableEstimate}>{data.estimate}</p>
                            </div>
                        ))}
                    </div>
                </div>,
            },
            {
                title: "What cleaning materials are used?",
                content: <div>
                    <div className={styles.contentHeader}>
                        <p>What our professionals bring along with them</p>
                    </div>
                    {whatCleaningMaterialsAreUsed.map((data, index) => (
                        <div key={index} className={`flex ${styles.dataContent}`}>
                            <img src={CheckSmall} alt="" />
                            <p className={styles.dataContentItem}>{data.content}</p>
                        </div>
                    ))}
                </div>,
            },
            {
                title: "Can I request additional hours?",
                content: <div className={`${styles.dataContent}`}>
                    <div className={styles.onlyText}>
                        <p>Feel free to discuss extra time with the cleaner. Additional hours will cost AED 34 per hour, subject to availability</p>
                    </div>
                </div>,
            },
            {
                title: "Cancellation policy",
                content: <div>
                    <div className={styles.contentTable}>
                        <div className={`flex ${styles.dataContent}`}>
                            <p className={` w-1/2 ${styles.header}`}>Time</p>
                            <p className={styles.header}>Cancellation fee</p>
                        </div>
                        {cancellationPolicy.map((data, index) => (
                            <div key={index} className={`flex ${styles.dataContent}`}>
                                <p className={` w-1/2  ${styles.tableTimePolicy}`}>{data.time}</p>
                                <p className={`w-1/2  ${styles.tablefeePolicy} `}>{data.fee}</p>
                            </div>
                        ))}
                    </div>
                </div>,
            },
        ],
    };

    const customStyle = {
        arrowColor: '#323E48',
        rowContentPaddingBottom: '10px',
        rowTitleTextSize: '16px',
        rowTitleTextWeight: '100',
        rowTitleColor: "#323E48",
    };
    

  return (
    <>
    <div className={styles.header}>
        <div className={styles.backArrow} onClick={()=> {navigate('/app/housekeeping/checkout')}}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
            </svg>
        </div>
        <div className={styles.image}>
            <p>House cleaning</p>
        </div>
    </div>
    <div className={` flex flex-col ${styles.article}`}>
        <p>
            We will send an adequate number of cleaning professionals for the best home cleaning possible. This task may take between 1-3h depending on the property size.
        </p>
    </div>
        <Faq
            data={data}
            styles={customStyle}
        />
    </>
  );
};


export default Info;
