import React, { useEffect, useState } from 'react';
import styles from '../styles/PaymentDrop.module.css';
import Modal from 'react-modal';
import { paymentMethods } from '../utils/paymentMethods';

// Define the component's props
interface PaymentDropdownProps {
  selectedMethod: string;
  selectedLabel: string;
  onChange: (method: string, label: string) => void;
}
const PaymentDropdown: React.FC<PaymentDropdownProps> = ({selectedLabel, selectedMethod, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(()=>{
    onChange(paymentMethods[0].value, paymentMethods[0].label);
  },[])
  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = paymentMethods.find(method => method.value === event.target.value);
    if (selectedOption) {
      onChange(selectedOption.value, selectedOption.label);
    }
    handleCloseModal();
  };

  const icon: () => string | undefined = ()=>{
    const selectedOption = paymentMethods.find(method => method.value === selectedMethod);
      return selectedOption?.icon
  }
  return (
    <div>
      <button className={`flex ${styles.button}`} onClick={handleOpenModal}>
        <div className={`flex ${styles.button}`}>
          <img className='mr-4' src={icon()} alt="" />
          {selectedLabel}
        </div>
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 16L15 12L11 8" stroke="#8B98A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </button>
      <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      ariaHideApp={false}
      className={styles.phoneModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          width: '100%',
          height: 'auto',
          margin: '0',
          padding: '24px 16px',
          gap: '24px',
          borderRadius: '8px',
          top: 'auto',
          bottom: '0',
          left: '0',
          right: '0',
          maxWidth: 'none',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
        <div className={styles.modalContent}>
          <div className='flex justify-between'>
            <h5>Payment method</h5>
            <span onClick={handleCloseModal}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" fill="#BCC4CC"/>
              <path d="M14.8337 9.16699L9.16699 14.8337M9.16699 9.16699L14.8337 14.8337L9.16699 9.16699Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </span>
          </div>
          <div className={styles.methods}>
          {paymentMethods.map((method) => (
              <label key={method.value} className={styles.radioLabel}>
                <input
                  type="radio"
                  value={method.value}
                  checked={selectedMethod === method.value}
                  onChange={handleChange}
                  className={styles.radioInput}
                />

                <span className={styles.labelText}><img src={method.icon} alt="" /> {method.label}</span>
                <span className={`${styles.customRadio} ${selectedMethod === method.value ? styles.checked : ''}`}></span>
              </label>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentDropdown;
