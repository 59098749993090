import React from "react";
import styles from "../styles/notfound.module.css"; // Import the CSS module

const EmptyOrders: React.FC = () => {
  return (
    <div className={`${styles.emptyOrders}`}>
        <img src="/50.svg" alt="" />
        <div className={styles.page}>
            <p>Orders not found</p>
        </div>
    </div>
  );
};

export default EmptyOrders;
