import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from '../../styles/selectTime.module.css'
import { fetchDubaiTime } from "../../utils/times/fetchDubaiTime";
import {twoRowTexts2 } from "../../utils/skeleton";


interface SelectTimeProps {
    onContinue: (TimeData: {dateSelected: string; timeSelected: string;}) => void;
  }

  const SelectTime: React.FC<SelectTimeProps> = ({ onContinue }) => {
    const [dateSelected, setDateSelected] = useState('');
    const [timeSelected, setTimeSelected] = useState('');
    const [times, setTimes] = useState<any>([]);
    const [dubaiTime, setDubaiTime] = useState<Date | null>(null);
    const [loading, setLoading] = useState<Boolean>(true)
    const navigate = useNavigate()

    const handleContinueClick = () => {
        const selectedData: any = { dateSelected: dateSelected, timeSelected: timeSelected };
        onContinue(selectedData);
    };

    const handleDateClick = (value: string)=>{
        setDateSelected(value)
    }

    const handleTimeClick = (value: string)=>{
        setTimeSelected(value)
    }

    useEffect(()=>{
        setDateSelected(generateDates()[0])
    },[dubaiTime])
    
    useEffect(()=>{
        const fetchAndSetTime = async () => {
            const fetchedTime = await fetchDubaiTime();
            if(fetchedTime){
                const timeNow = new Date(fetchedTime) 
                setDubaiTime(timeNow)
                setLoading(false)
            }
        }
        fetchAndSetTime()
    },[])


    useEffect(() => {
        if(dubaiTime){
            const now = dubaiTime;
            const currentHour = now.getHours();
            const currentMinute = now.getMinutes();

            if (dateSelected === generateDates()[0] && currentHour < 16) {
                const upcomingTimes = getNearestUpcomingTimeSlot(currentHour, currentMinute);
                setTimes(upcomingTimes);
                setTimeSelected(upcomingTimes[0]);
            } else {
                setTimeSelected(generateTimes()[0]);
                setTimes(generateTimes());
            }
        }
      }, [dateSelected, dubaiTime]);
    

    const generateTimes = () => {
        const times = [];
        for (let hour = 8; hour <= 18; hour++) {
            for (let minute of [0, 30]) {
                const hour12 = hour % 12 || 12;
                if(hour === 18 && minute !== 0) return times;
                const minuteString = minute === 0 ? '00' : `${minute}`;
                const period = hour < 12 ? 'AM' : 'PM';
                const timeString = `${hour12}:${minuteString} ${period}`;
                times.push(timeString);
            }
        }
        return times;
    };
    
    const getNearestUpcomingTimeSlot = (currentHour: number, currentMinute: number) => {
        const times = generateTimes();
        const currentTimeInMinutes = currentHour * 60 + currentMinute;
        const nearestTimeSlotIndex = times.findIndex((time) => {
            const [hourMinute, period] = time.split(' ');
            let [hour, minute] = hourMinute.split(':').map(Number);
            if (period === 'PM' && hour !== 12) hour += 12;
            if (period === 'AM' && hour === 12) hour = 0;
            const timeInMinutes = hour * 60 + minute;
            // Adjust the condition to ensure it stops at 6:00 PM (18:00)
            return timeInMinutes >= currentTimeInMinutes + 180 && timeInMinutes <= 18 * 60;
        });
        return times.slice(nearestTimeSlotIndex);
    };

    const generateDates = () => {
        const dates = [];
        const today = dubaiTime;
    
        if (today) {
            for (let i = 0; i < 7; i++) {
                const futureDate = new Date(today);
                futureDate.setDate(today.getDate() + i);
    
                const options: any = { weekday: 'long', day: 'numeric', month: 'long' };
                let dateString;
                const currentHour = today.getHours();
    
                if (i === 0 && currentHour >= 16) {
                    continue;
                }
    
                if (i === 0 && currentHour < 16) {
                    const dayMonth = today.toLocaleDateString('en-US', { day: 'numeric', month: 'long' });
                    dateString = `Today, ${dayMonth}`;
                } else {
                    dateString = futureDate.toLocaleDateString('en-US', options);
                }
    
                dates.push(dateString);
            }
        }
    
        return dates;
    };


      
  return (
    <div className={styles.container}>
        <header>
            <div className={`flex ${styles.upper} place-content-between items-center `}>
                <span onClick={()=>{navigate("/app")}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
                    </svg>
                </span>
                <Link to="/app/housekeeping/info">Learn more</Link>
            </div>
            <div className={styles.title}>
                <h1>Select start time</h1>
                <p>This service will take approximately 2 hours</p>
            </div>
        </header>
        <div className={`flex ${styles.select} ${styles.cleaningTime}`}>
            <div className={`${styles.date}`}>
                {loading ? (
                    <>
                        <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
                        <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
                        <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
                        <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
                    </>
                ):(
                    <>
                        {generateDates().map((date: string, index: number) => (
                            <div 
                                key={index}
                                onClick={() => handleDateClick(date)}
                                className={`${styles.selectBox} ${dateSelected === date ?  `${styles.selected}` : ''}`}>
                                    <p>{date.split(', ')[0]}</p>
                                    <p>{date.split(', ')[1]}</p>
                            </div>
                        ))}
                    </>
                )}
            </div>
            <div className={`${styles.time}`}>
            {loading ? (
                    <>
                        <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
                        <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
                        <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
                        <div className={styles.skeletonLoading}>{twoRowTexts2()}</div>
                    </>
                ):(
                    <>
                        {times.map((time: string, index: number) => (
                            <div 
                                key={index}
                                onClick={() => handleTimeClick(time)}
                                className={`${styles.selectBox} ${timeSelected === time ?  `${styles.selected}` : ''}`}>
                                    <p>{time}</p>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
        <div className={styles.footer}>
            <button onClick={handleContinueClick}>Continue to checkout</button>
        </div>
    </div>
  );
};

export default SelectTime;
