import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/laundryService/selectTime.module.css";
import PickupTimes from "./pickup";
import { generateDates, getNearestUpcomingTimeSlot } from "../../utils/times/generateDoubleTimeDate";
import DropoffTimes from "./dropoff";
import { generateDropoffDates, getDropoffTimes } from "../../utils/times/dropoffTimeDate";
import { threeRowTexts } from "../../utils/skeleton";
import { fetchDubaiTime } from "../../utils/times/fetchDubaiTime";


export type DropoffTime = {
  createdAt: string;
  extraFee: string;
  id: number;
  slot: string;
  type: string;
  updatedAt: string;
};

interface SelectTimeProps {
  onContinue: (
    pickupData: { pickupDate: string,  pickupTime: string},
    dropoffData: {dropOffDate: string, dropOffTime: DropoffTime},
  ) => void;
  onBack: () => void;
}

const LaundrySelectTime: React.FC<SelectTimeProps> = ({onBack, onContinue }) => {
  const navigate = useNavigate();
  const [isPickupTime, setIsPickupTime] = useState<boolean>(false);
  const [isDropOffTime, setIsDropOffTime] = useState<boolean>(false);
  const [pickupDate, setPickupDate] = useState<string>("");
  const [pickupTime, setPickupTime] = useState<string>("");
  const [dropOffTime, setDropOffTime] = useState<DropoffTime | null>(null);
  const [dropOffDate, setDropOffDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [dropOffmodalIsOpen, setDropOffmodalIsOpen] = useState(false);
  const [pickupmodalIsOpen, setPickupmodalIsOpen] = useState(false);

  const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
  const [pickupshouldAnimateOut, setPickupShouldAnimateOut] = useState(false);
  const [dubaiTime, setDubaiTime] = useState<Date | null>(null);

  const openDropoffModal = () => {
    setDropOffmodalIsOpen(true);
    setShouldAnimateOut(false);

  };
  const closeDropoffModal = () => {
    setShouldAnimateOut(true);

  };

  const openPickupModal = () => {
    setPickupmodalIsOpen(true);
    setPickupShouldAnimateOut(false);

  };
  const closePickupModal = () => {
    setPickupShouldAnimateOut(true);

  };

  useEffect(() => {
    if (shouldAnimateOut) {
      const timer = setTimeout(() => {
        setDropOffmodalIsOpen(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [shouldAnimateOut]);

  useEffect(() => {
    if (pickupshouldAnimateOut) {
      const timer = setTimeout(() => {
        setPickupmodalIsOpen(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [pickupshouldAnimateOut]);
  
  useEffect(()=>{
    if(dubaiTime){
      const now = dubaiTime;
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      setPickupDate(generateDates(dubaiTime)[0]);
      setPickupTime(getNearestUpcomingTimeSlot(currentHour, currentMinute)[0]);
    }
  },[dubaiTime])

  useEffect(()=>{
    const fetchAndSetTime = async () => {
        const fetchedTime = await fetchDubaiTime();
        if(fetchedTime){
            const timeNow = new Date(fetchedTime) 
            setDubaiTime(timeNow)
            setLoading(false)
        }
    }
    fetchAndSetTime()
},[])

  useEffect(()=>{
    fetchTimes();
  },[pickupDate])


  const fetchTimes = async () => {
    try {
      const timesData = await getDropoffTimes();
      if (timesData && dubaiTime) {
        
        setDropOffDate(generateDropoffDates(pickupDate || generateDates(dubaiTime)[0])[0]);
        setDropOffTime(timesData[0])

      }
    } catch (error) {
      console.error(error);
    } finally{
    }
  };
  
  const handlePickupTime = (pickDate: string, pickTime: string) => {
    setPickupDate(pickDate);
    setPickupTime(pickTime);
    setIsPickupTime(false);
  };
  
  const handleDropOffTime = (dropoffDate: string, dropoffTime: any) => {
    setDropOffDate(dropoffDate);
    setDropOffTime(dropoffTime);
    setIsDropOffTime(false);
  };

  const onSubmit = () =>{
    if (!dropOffTime) return
    onContinue(
      { pickupDate, pickupTime },
      { dropOffDate, dropOffTime }
    );
  }
  
  return (
    <div className={styles.selectTimeC}>

      <header>
        <div
          className={`flex ${styles.upper} place-content-between items-center `}
        >
          <span
            onClick={() => onBack()}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
            </svg>
          </span>
        </div>
        <div className={styles.title}>
          <h1>Confirm timings</h1>
          <p>We’ve pre-selected the earliest available slots</p>
        </div>
      </header>
      {!(isPickupTime || isDropOffTime) &&  
        <>
          <div className={styles.laundrySections}>
          {loading ? (
            <div className={`${styles.section}`}>
              {threeRowTexts()}
            </div>
          ):(
            <div onClick={openPickupModal} className={`${styles.section}`}>
              <div className={`flex  ${styles.sectionData}`}>
                <div className={styles.timeData}>
                  <p>Pick up</p>
                  <p>{pickupDate}</p>
                  <p>{pickupTime}</p>
                </div>
              </div>
            </div>
          )}
          {loading ? (
            <div className={`${styles.section}`}>
              {threeRowTexts()}
            </div>
          ):(
            <div onClick={openDropoffModal} className={`${styles.section}`}>
              <div className={`flex  ${styles.sectionData}`}>
                <div className={styles.timeData}>
                  <p>Drop off</p>
                  <p>{dropOffDate}</p>
                  <p>{dropOffTime && dropOffTime.slot}</p>
                </div>
              </div>
            </div>
          )}
          </div>
          <div className={styles.footer}>
            <button onClick={onSubmit}>Continue</button>
          </div>
       </>
      }
      <PickupTimes shouldAnimateOut={pickupshouldAnimateOut} isOpen={pickupmodalIsOpen} onRequestClose={closePickupModal} onConfirm={handlePickupTime} />
      {pickupDate &&
        <DropoffTimes shouldAnimateOut={shouldAnimateOut} isOpen={dropOffmodalIsOpen} onRequestClose={closeDropoffModal}  pickupDate={pickupDate} onConfirm={handleDropOffTime} />
      }

    </div>
  );
};

export default LaundrySelectTime;
