import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../../styles/groceryService/subCategories.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
// import CategoryItems from "./CategoryItems";
import axios from "axios";
import { centeredImgOnetext, itemSkeleton, sectionsSkeleton } from "../../utils/skeleton";

type menuSection = {
  id: number;
  name: string;
};

interface SubCategoriesProps {
    onCategorySelect: (categoryId: number, isParent:  boolean) => void;
  }
  
  const SubCategories: React.FC<SubCategoriesProps> = ({ onCategorySelect }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [, setParentName] = useState<string>("")
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [categories, setCategories] = useState<menuSection[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);


  useEffect(()=>{
    if (activeIndex === -1 && id)
        onCategorySelect(parseInt(id), true);
  },[])
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}services/groceries/categories/${id}/subcategories`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        setLoading(false);
        setCategories(response.data);
      } catch (error) {
        setCategories([]);
      } finally {
      }
    };

    fetchServices();
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleSubcategoryClick = (categoryId: number, index: number) => {
    setActiveIndex(index);

    if (index === -1)
        onCategorySelect(categoryId, true);
    else
        onCategorySelect(categoryId, false);
  };

  return (
    <>
      {loading ? (
        <>
          <div className={`${styles.menuSections} ${styles.scrolledMenu}`}>{sectionsSkeleton()}</div>
        </>
      ) : (
        <>
          <div
            className={`${styles.menuSections} ${
                styles.scrolledMenu
            }`}
          >
            <ul className={`${styles.sectionsHeader} flex items-center`}>
              <div className={styles.sectionDrop}>
                <svg
                  onClick={toggleDropdown}
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="37"
                    height="37"
                    rx="18.5"
                    fill="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="37"
                    height="37"
                    rx="18.5"
                    stroke="#E4E8EC"
                  />
                  <path
                    d="M16.7 14H25.7"
                    stroke="#323E48"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M16.7 19H25.7"
                    stroke="#323E48"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M16.7 24H25.7"
                    stroke="#323E48"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12.3 14H13.3"
                    stroke="#323E48"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12.3 19H13.3"
                    stroke="#323E48"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12.3 24H13.3"
                    stroke="#323E48"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
                <li
                  className={-1 === activeIndex ? styles.active : ""}
                  onClick={() => id ? handleSubcategoryClick(parseInt(id), -1) : ""}
                >
                  All
                </li>
              {categories.map((section, index) => (
                <li
                  key={index}
                  className={index === activeIndex ? styles.active : ""}
                  onClick={() => handleSubcategoryClick(section.id, index)}
                >
                  {section.name}
                </li>
              ))}
            </ul>
            {dropdownVisible && (
              <div
                onClick={() => setDropdownVisible(false)}
                className={styles.overlay}
              ></div>
            )}
            {dropdownVisible && (
              <ul className={styles.sectionDropDown}>
                <li
                    className={` ${styles.dropdownItem} ${
                    -1 === activeIndex ? styles.active : ""
                    }`}
                    onClick={() => id ? handleSubcategoryClick(parseInt(id), -1) : ""}

                >
                  All
                </li>
                {categories.map((item: any, index: number) => (
                  <li
                    key={index}
                    className={` ${styles.dropdownItem} ${
                      index === activeIndex ? styles.active : ""
                    }`}
                    onClick={() => handleSubcategoryClick(item.id, index)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SubCategories;
