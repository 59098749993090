import React, { useEffect, useState } from "react";
import styles from "../../styles/foodService/menuItem.module.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import subIcon from "../../assets/icons/sub.svg";
import coloredSubIcon from "../../assets/icons/coloredSub.svg";
import {ReactComponent as ReacttrashIcon} from '../../assets/icons/trash.svg'
import {ReactComponent as ReactplusIcon} from '../../assets/icons/plus.svg'
import {ReactComponent as ReactColoredsubIcon} from '../../assets/icons/coloredSub.svg'
import Loader from "../../components/Loader";
import notify from "../../components/Errortoast";

type GroupItem = {
  id?: string;
  masterId?: string;
  name: string;
  price: number;
  quantity: number;
};

type Group = {
  id: string;
  name: string;
  items: GroupItem[];
};

type Item = {
  id: number;
  price: number;
  discountedPrice: number;
  name: string;
  image: object;
  quantity: number;
  totalPrice: number;
  additionalNotes?: string
  groups: Group[];
  groupsTotal: number;
};


const MenuItem: React.FC = () => {
  const navigate = useNavigate();
  const [item, setItem] = useState<any>(null)
  const [count, setCount] = useState<number>(1);
  const [counts, setCounts] = useState<Item | null>(null);
  const [checkedItems, setCheckedItems] = useState<any>({});
  const [checkedRadioItems, setCheckedRadioItems] = useState<any>({});
  const [groupIsRequired, setGroupIsRequired] = useState<any> ({})
  const [isRequired, setIsRequired] = useState<boolean> (false)
  const [loading, setLoading] = useState<boolean> (true)
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const { id } = useParams<{ id: string }>();

  useEffect(()=>{
    const fetchItem = async () => {
    try {
      const token = localStorage.getItem("PropertyToken");
      if (!token) {
        throw new Error("PropertyToken not found");
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}services/food/menu/items/${id}`,
        {
          headers: {
            propertyToken: token,
          },
        }
      );

      
      setItem(response.data);

    } catch (error) {
      navigate("/app/food/checkout")
      notify({ type: 'error', content: "Item is not available" });
    }finally{
      setLoading(false)
    }
  }

  fetchItem()
  },[])

  useEffect(()=>{
    if(item)
    setCounts(
      {
        id: item.id,
        name: item.name,
        price: (Math.round(item.grossPrice * (1 - item.discount) * 100) / 100),
        discountedPrice: (item.grossPrice * item.discount),
        quantity: count,
        image: item.imageUrls,
        totalPrice: 0,
        groups: [],
        groupsTotal: 0
      })
  }, [item])
  
  const findGroupIndex = (groups: Group[], groupId: string) => groups.findIndex(group => group.id === groupId);
  
  const mainIncrement = () => {
    setCount(count + 1)
    setCounts((prevCounts: Item | null) => {
      if (!prevCounts) return null;
      prevCounts.quantity += 1
      return prevCounts;
    });
  };
  const mainDecrement = () => {
    count > 0 && setCount(count - 1)
    setCounts((prevCounts: Item | null) => {
      if (!prevCounts) return null;
      prevCounts.quantity -= 1
      return prevCounts;
    });
  };
  
const increment = (groupId: string, groupName: string, itemId: string, itemName: string,  price: number) => {
  setCounts((prevCounts: Item | null)  => {
    if (!prevCounts) return null;

    const groupIndex = findGroupIndex(prevCounts.groups, groupId);
    const newGroups = [...prevCounts.groups];

    if (groupIndex === -1) {
      // Group does not exist, add it with the new item
      newGroups.push({ id: groupId, name: groupName, items: [{ id: itemId, name: itemName, price, quantity: 1 }] });
    } else {
      const group = newGroups[groupIndex];
      const itemIndex = group.items.findIndex(item => item.id === itemId);

      if (itemIndex === -1) {
        // Item does not exist, add it
        group.items.push({ id: itemId, name: itemName, price, quantity: 1 });
      } else {
        // Item exists, update quantity
        group.items[itemIndex].quantity += 1;
      }
    }

    return { ...prevCounts, groups: newGroups };
  });
};

const decrement = (groupId: string, itemId: string) => {
  setCounts((prevCounts: Item | null) => {
    if (!prevCounts) return null;

    const groupIndex = findGroupIndex(prevCounts.groups, groupId);
    if (groupIndex === -1) return prevCounts; // Group does not exist

    const newGroups = [...prevCounts.groups];
    const group = newGroups[groupIndex];
    const itemIndex = group.items.findIndex(item => item.id === itemId);

    if (itemIndex !== -1) {
      const updatedItems = [...group.items];
      updatedItems[itemIndex].quantity -= 1;
      if (updatedItems[itemIndex].quantity <= 0) {
        // Remove item if quantity is zero or less
        updatedItems.splice(itemIndex, 1);
      }

      newGroups[groupIndex].items = updatedItems;
    }

    return { ...prevCounts, groups: newGroups };
  });
};


const handleCheckboxChange = (groupId: string, groupName: string, itemId: string, itemName: string, isChecked: boolean, price: number) => {
  setCounts((prevCounts: Item | null) => {
    if (!prevCounts) return null;

    const groupIndex = findGroupIndex(prevCounts.groups, groupId);
    const newGroups = [...prevCounts.groups];

    if (groupIndex === -1) {
      // Group does not exist, add it with the new item
      newGroups.push({ id: groupId, name: groupName, items: [{ id: itemId, name: itemName, price, quantity: isChecked ? 1 : 0 }] });
    } else {
      const group = newGroups[groupIndex];
      const itemIndex = group.items.findIndex(item => item.id === itemId);

      if (isChecked) {
        if (itemIndex === -1) {
          // Item does not exist, add it
          group.items.push({ id: itemId,name: itemName, price, quantity: 1 });
        } else {
          // Item exists, update quantity
          group.items[itemIndex].quantity += 1;
        }
      } else {
        if (itemIndex !== -1) {
          const updatedItems = [...group.items];
          updatedItems[itemIndex].quantity -= 1;

          if (updatedItems[itemIndex].quantity <= 0) {
            // Remove item if quantity is zero or less
            updatedItems.splice(itemIndex, 1);
          }

          group.items = updatedItems;
        }
      }
    }

    return { ...prevCounts, groups: newGroups };
  });

  setCheckedItems((prevState: any) => ({
    ...prevState,
    [itemId]: isChecked,
    [groupId]: isChecked 
  }));
};



const handleRadioChange = (
  groupId: string, 
  groupName: string, 
  itemId: string, 
  itemName: string, 
  isChecked: boolean, 
  price: number
) => {
  setCounts((prevCounts: Item | null) => {
    if (!prevCounts) return null

    const newGroups = prevCounts.groups.map(group =>
      group.id === groupId
        ? {
            ...group,
            items: isChecked ? [{ id: itemId, name: itemName, price, quantity: 1 }] : [],
          }
        : group
    );

    if (!newGroups.find(group => group.id === groupId) && isChecked) {
      newGroups.push({ id: groupId, name: groupName, items: [{ id: itemId, name: itemName, price, quantity: 1 }] });
    }

    return { ...prevCounts, groups: newGroups };
  });

  setCheckedRadioItems((prevState: any) => {
    const newState = { ...prevState };
    if (isChecked) {
      newState[groupId] = itemId; // Set the current item as selected for this group
    } else {
      delete newState[groupId]; // Remove the selection if unchecked (though typically radio buttons don't get unchecked)
    }
    return newState;
  });
};


const totalGroupCount = (groupId: string): number => {
  if(counts === null) return 0;
  const group = counts.groups.find((group: Group) => group.id === groupId);
  return group ? group.items.reduce((total:number, item:GroupItem) => total + item.quantity, 0) : 0;
};

const sumItemGroupsPrices = (item: Item): {totalPrice: number, groupsTotal: number} => {
  const groupsTotal = item.groups.reduce((total, group) => {
    const groupTotal = group.items.reduce((groupSum, groupItem) => {
      return groupSum + (groupItem.price * groupItem.quantity);
    }, 0);
    return total + groupTotal;
  }, 0);
  
  return {totalPrice: count * (Number(item.price) + groupsTotal), groupsTotal}
};


const checkgroups = async () => {
  if(!item) return
  item.groups.map((group: any) => {
    const isItemExist = counts?.groups.find((e) => e.id === group.masterId);  
    if (
        group.quantity.minimum > 0 &&
        !group.hideFromOrdering &&
        ( (!isItemExist) ||
          (isItemExist && isItemExist.items.length < group.quantity.minimum))
      ) {
        setGroupIsRequired((prevState: any) => ({
          ...prevState,
          [group.masterId]: true
        }));
    } else {
      setGroupIsRequired((prevState: any) => {
        const { [group.masterId]: _, ...rest } = prevState;
        return rest;
      });
    }
  });
};

useEffect(()=>{
  checkgroups();
}, [counts])


const handleSubmit = async () => {
  if (!counts) return;

  checkgroups();

  if (Object.keys(groupIsRequired).length > 0) {
    const element = document.getElementById(Object.keys(groupIsRequired)[0]);
    if(element)
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setIsRequired(true);
    return;
  }
  setIsRequired(false);

  const ordersString = localStorage.getItem("orderItems");
  const orders: Item[] = ordersString ? JSON.parse(ordersString) : [];
  
  const prices = sumItemGroupsPrices(counts);
  
  const newOrderItem = {
    ...counts,
    ...(additionalNotes.length > 0 && { additionalNotes }),
    totalPrice: prices.totalPrice,
    groupsTotal: prices.groupsTotal
  };
  
  const existingOrderIndex = orders.findIndex(
    (item: Item) => item.id === newOrderItem.id && JSON.stringify(item.groups) === JSON.stringify(newOrderItem.groups)
  );
  
  if (existingOrderIndex !== -1) {
    orders[existingOrderIndex].quantity += newOrderItem.quantity;
    orders[existingOrderIndex].totalPrice += newOrderItem.totalPrice; // Update the total price
  } else {
    orders.push(newOrderItem);
  }
  
  localStorage.setItem("orderItems", JSON.stringify(orders));

  navigate(`/app/food/checkout?item=${newOrderItem.id}`);
}



const groupShow = (group: any) => {
  const inputType = () => {
    if (group.quantity.maximum === 1 && group.quantity.sameItem === 1) {
      return "Radios";
    } else if (group.quantity.maximum > 1 && group.quantity.sameItem === 1) {
      return "Checkboxes";
    } else if (group.quantity.maximum > 1 && group.quantity.sameItem > 1) {
      return "Counter";
    }
    return "";
  };

  const items = group.menuItems.length > 0 ? group.menuItems : group.addons;
  return (
    <div id={group.masterId} key={group.masterId} className={styles.group}>
      <h5>{group.name}</h5>
      <span
          className={`
                      ${
                        group.quantity.minimum > 0 && isRequired && groupIsRequired[group.masterId]
                          ? styles.forcedRequired
                          : ""
                      }
                      ${
                        group.quantity.minimum > 0
                          ? styles.required
                          : styles.optional
                      }`
                    
                    }
        >
          {group.quantity.minimum > 0 ? "Required" : "Optional"}
        </span>
        <span> • Choose up to {group.quantity.maximum}</span>

      {items.map((item: any) => {
        if (item.outOfStock) return null;
        const shouldDimText = counts !== null && 
                              inputType() !== "Counter" && 
                              checkedRadioItems[group.masterId] !== (item.masterId || item.id) && 
                              !checkedItems[item.masterId || item.id] && 
                              totalGroupCount(group.masterId) >= group.quantity.maximum;
        return (
          <div className={`flex justify-between items-center ${styles.groupItem}`} key={item.masterId || item.id}>
            <p className={`${shouldDimText ? styles.dimText : ""}  
                            ${styles.name}`}>{item.name}</p>
            <div className="flex gap-2">
              {item.price && item.price.gross !== 0 && (
                <span>+AED {item.price.gross}</span>
              )}
              {inputType() === "Checkboxes" &&
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    id={item.name}
                    className={`${styles.customCheckbox}`}
                    disabled={counts !== null  && !checkedItems[item.id] && totalGroupCount(group.masterId) >= group.quantity.maximum}
                    onChange={(e) => handleCheckboxChange(group.masterId, group.name, item.masterId || item.id, item.name, e.target.checked, item.price.gross)}
                  />
                  <label htmlFor={item.name} className={`${counts !== null && !checkedItems[item.id] && totalGroupCount(group.masterId) >= group.quantity.maximum ? styles.dim : styles.checkboxLabel} `}></label>
                </div>
              }
              {inputType() === "Radios" &&
                <label className={styles.radioLabel}>
                  <input
                    id={item.name}
                    type="radio"
                    checked={checkedRadioItems[group.masterId] === (item.masterId || item.id)}
                    value={item.masterId || item.id}
                    onChange={(e) => handleRadioChange(
                      group.masterId, 
                      group.name, 
                      item.masterId || item.id, 
                      item.name, 
                      true,
                      item.price.gross
                    )}
                    className={styles.radioInput}
                  />
                  <span 
                    className={`${counts !== null && !checkedRadioItems[item.id] && totalGroupCount(group.masterId) >= group.quantity.maximum ? styles.dim : ""} 
                                ${styles.customRadio} 
                                ${checkedRadioItems[group.masterId] === (item.id || item.masterId) ? styles.checked : ''}`}>
                  </span>
                </label>
              }
              {inputType() === "Counter" &&
                <div className={styles.add}>
                  <div className={`${counts !== null && !checkedItems[item.id] && totalGroupCount(group.masterId) >= group.quantity.maximum ? styles.counterDim : styles.container}`}>
                    {counts !== null && !counts.groups.find(cgroup => cgroup.id === group.masterId)?.items.find(citem => citem.id === (item.masterId || item.id)) ? (
                      <button 
                        className={`flex items-center ${styles.plus}`}
                        disabled={counts !== null && totalGroupCount(group.masterId) >= group.quantity.maximum}
                        onClick={() => increment(group.masterId, group.name, item.masterId || item.id, item.name, item.price.gross)}
                      >
                        <ReactplusIcon />
                      </button>
                    ) : (
                      <div className={styles.counter}>
                        <button
                          className={styles.button}
                          onClick={() => decrement(group.masterId, item.masterId || item.id)}
                          disabled={
                            counts !== null &&
                            counts.groups.find(cgroup => cgroup.id === group.masterId)
                              ?.items.find(citem => citem.id === (item.masterId || item.id))
                              ?.quantity === 0
                          }
                        >
                          {counts !== null && counts.groups.find(cgroup => cgroup.id === group.masterId)
                            ?.items.find(citem => citem.id === (item.masterId || item.id))
                            ?.quantity === 1 
                            ? (<ReacttrashIcon/>) 
                            : (<ReactColoredsubIcon/>)
                          }
                        </button>
                        <span className={styles.count}>
                          {counts !== null && (counts.groups.find(cgroup => cgroup.id === group.masterId)
                            ?.items.find(citem => citem.id === (item.masterId || item.id))
                            ?.quantity ?? 0)
                          }
                        </span>
                        <button 
                          disabled={
                            counts !== null && (
                              totalGroupCount(group.masterId) >= group.quantity.maximum || 
                              (counts.groups.find(cgroup => cgroup.id === group.masterId)
                                ?.items.find(citem => citem.id === (item.masterId || item.id))
                                ?.quantity ?? 0) >= group.quantity.sameItem
                            )
                          }
                          className={`${styles.plus} ${styles.button}`} 
                          onClick={() => increment(group.masterId, group.name, item.masterId || item.id, item.name, item.price.gross)}
                        >
                          <ReactplusIcon/>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        );
      })}
    </div>
  );
};

  return (
    <>

    {loading ? (
      <>
        <Loader/>
      </>
    ) : (
    <div className={styles.menuItems}> 

      <div className={styles.header}>
        <div
          className={styles.backArrow}
          onClick={() => {
            navigate("/app/food/checkout");
          }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
          </svg>
        </div>
        <div className={styles.image} 
              style={{background: `url(${item.imageUrls["720_720"]}) lightgray 50% / cover no-repeat`}}></div>
      </div>
      <div className={`${styles.article}`}>
        <h2>{item.name}</h2>
        <p>{item.description}</p>
      </div>
      <div className={styles.groups}>
      { item.groups.map((group: any) => (
        !group.hideFromOrdering && (
            groupShow(group)
        )
      ))}

      <div className={styles.additionalNotes}>
          <h5>Special requests</h5>
          <div className={` flex ${styles.serviceDetail}`}>
            <input
              type="text"
              placeholder="Add a message for the restaurant"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </div>
        </div>

      </div>
      {!item.outOfStock && 
        <div className={`flex ${styles.footer}`}>
          <div className={styles.counter}>
            <button
              className={styles.button}
              onClick={mainDecrement}
              disabled={count === 1}
            >
              {count > 1 ? (
                <img src={coloredSubIcon} alt="" />
              ) : (
                <img src={subIcon} alt="" />
              )}
            </button>
            <span className={styles.count}>{count}</span>
            <button
              disabled={count === 50}
              className={styles.button}
              onClick={mainIncrement}
            >
              <ReactplusIcon/>
            </button>
          </div>
            <button onClick={handleSubmit} className={`flex items-center justify-between ${styles.continue}`}>
              <p>Add</p>
              <div className="flex flex-col gap-0">
                {item.discount > 0 &&
                  <p className={styles.priceDiscount}>AED {counts && (sumItemGroupsPrices(counts).totalPrice + (counts.quantity * (counts.discountedPrice))).toFixed(2)}</p>
                }
                <p>AED {counts && sumItemGroupsPrices(counts).totalPrice.toFixed(2)}</p>
              </div>
            </button>  
        </div>
      }
    </div>
    )}
    </>
  );
};

export default MenuItem;
