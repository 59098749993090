import React from "react";
import Modal from "react-modal";
import styles from "../styles/contactModal.module.css";

interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ContactModal: React.FC<CustomModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          background: "none",
          border: "none",
          width: "100%",
          height: "auto",
          margin: "0",
          padding: "24px 8px",
          gap: "24px",
          borderRadius: "8px",
          top: "auto",
          bottom: "0",
          left: "0",
          right: "0",
          maxWidth: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <div className={`flex flex-col ${styles.help}`}>
        <button onClick={() => (window.location.href = `tel:${"042455800"}`)}>
          Call (04) 245 5800
        </button>
        <button onClick={onRequestClose}>Cancel</button>
      </div>
    </Modal>
  );
};

export default ContactModal;
