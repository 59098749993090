import React, { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import SelectItem from "../../components/towelsService/selectItem";
import Payment from "../../components/towelsService/payment";
import "../../styles/switchTransition.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface TowelsData {
  id: number;
  count: number;
}

const TowelCheckout: React.FC = () => {
  const [currentComponent, setCurrentComponent] = useState<'selectItem' | 'payment'>('selectItem');
  const [selectedTowelsData, setSelectedTowelsData] = useState<TowelsData | null>(null);
  const [isForward, setIsForward] = useState<boolean>(true);
  const [themeColor, setThemeColor] = useState('#ffffff'); 
  const navigate = useNavigate()

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem("PropertyToken");
        if (!token) {
          throw new Error("PropertyToken not found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}user-services/${4}`,
          {
            headers: {
              propertyToken: token,
            },
          }
        );
        if (!response.data.isAvailable)
          navigate("/app")
      } catch (error) {
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    const primaryHeaderColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-header-color');
    setThemeColor(primaryHeaderColor.trim());
  }, []);

  const handleContinue = (data: TowelsData) => {
    setSelectedTowelsData(data);
    setIsForward(true);
    setTimeout(() => {
      setCurrentComponent('payment');
    }, 100);
  };

  const handleBack = () => {
    setIsForward(false);
    setTimeout(() => {
      setCurrentComponent('selectItem');
    }, 100);
  };

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={currentComponent}
        timeout={200}
        classNames={isForward ? "fade-forward" : "fade-backward"}
      >
        <div className="transition-wrapper">
          {currentComponent === 'selectItem' && (
            <>
              <Helmet>
                <meta name="theme-color" content={themeColor} />
              </Helmet>
              <SelectItem onContinue={handleContinue} />
            </>
          )}
          {currentComponent === 'payment' && (
            <>
              <Helmet>
                <meta name="theme-color" content="#fff" />
              </Helmet>
              <Payment onBack={handleBack} towelsData={selectedTowelsData} />
            </>
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default TowelCheckout;
