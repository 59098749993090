import React, { useEffect, useState } from "react";
import styles from "../../styles/foodService/basket.module.css";
import "react-loading-skeleton/dist/skeleton.css";
import {ReactComponent as ReactplusIcon} from '../../assets/icons/plus.svg'
import {ReactComponent as ReactColoredsubIcon} from '../../assets/icons/coloredSub.svg'
import {ReactComponent as ReacttrashIcon} from '../../assets/icons/trash.svg'


interface PaymentProps {
  onBack: () => void
  onContinue: (counts: Item[], additionalNotes: string) => void;
}

type GroupItem = {
    id?: string;
    masterId?: string;
    name: string;
    price: number;
    quantity: number;
  };
  
  type Group = {
    id: string;
    name: string;
    items: GroupItem[];
  };

type Item = {
    id: string;
    price: number;
    name: string;
    image: object;
    quantity: number;
    groups: Group[];
    totalPrice: number;
    groupsTotal: number;
  };
  
const Basket: React.FC<PaymentProps> = ({
  onBack,
  onContinue
}) => {
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const [orderItems, setOrderItems] = useState<Item[]>([]);
  const [activeItem, setActiveItem] = useState<any>(null);
  const [discountedAmount, setDiscountedAmount] = useState<number> (0)
  const handleCounterClick = (id: string, groups:Group[]) => {
    setActiveItem((prevItem: any) => (prevItem && prevItem.id === id && JSON.stringify(prevItem.groups) === JSON.stringify(groups) ? prevItem : {id, groups}));
  };

  useEffect(() => {
    const orderItemsFromStorage = localStorage.getItem('orderItems');
    if (orderItemsFromStorage) {
      setOrderItems(JSON.parse(orderItemsFromStorage));
    }

    if(orderItemsFromStorage && JSON.parse(orderItemsFromStorage).length <= 0)
        onBack()

  }, []);

  useEffect(()=>{
    setDiscountedAmount(orderItems.reduce((sum:number, item:any) => sum + (item.discountedPrice * item.quantity), 0))

  },[orderItems])

  

  const increaseQuantity = (itemId: string, groups: Group[]) => {
    const updatedItems = orderItems.map((item) =>
      item.id === itemId &&
      item.quantity < 100 &&
      JSON.stringify(item.groups) === JSON.stringify(groups)
        ? {
            ...item,
            quantity: item.quantity + 1,
            totalPrice: item.totalPrice + (item.price + item.groupsTotal),
          }
        : item
    );
    setOrderItems(updatedItems);
    localStorage.setItem('orderItems', JSON.stringify(updatedItems));
  };

  const decreaseQuantity = (itemId: string, groups: Group[]) => {
    const updatedItems = orderItems.map(item =>
      item.id === itemId && JSON.stringify(item.groups) === JSON.stringify(groups) && item.quantity > 0
        ? { ...item, quantity: item.quantity - 1, totalPrice: item.totalPrice - (item.price + item.groupsTotal) }
        : item
    ).filter(item => item.quantity > 0);
  
    setOrderItems(updatedItems);

    localStorage.setItem('orderItems', JSON.stringify(updatedItems));

    if(updatedItems.length <= 0)
        onBack();
  };
  

  const subAmount = orderItems.reduce((sum:number, item:any) => sum + item.totalPrice, 0).toFixed(2)
  const deliveryFee = 7.00
  const totalAmount = (parseFloat(subAmount) + deliveryFee).toFixed(2)

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div
          className={styles.backArrow}
          onClick={() => onBack()}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 6L8 12.1222L14 18.2444" stroke="#323E48" stroke-width="3" stroke-miterlimit="10"/>
          </svg>
        </div>
      </div>
      <div className={styles.checkOut}>
        <h1>Basket</h1>
        <div className={styles.service}>
            {orderItems.map((item: any) => (
              <div className={` flex ${styles.section}`}>
                <img
                    src={item.image["200_200"]}
                    alt=""
                />
                <div className='flex-1 flex flex-col gap-2'>
                    <h2>{item.name}</h2>
                      {item.groups.map((group: any)=>(
                        <div>
                            <p style={{fontWeight: "500"}}>{group.name}</p>
                            <>
                            {group.items.map((item: any) => (
                              <p>{item.quantity}x {item.name}</p>
                            ))}
                            </>
                        </div>
                      ))}
                    <div className="flex gap-2">           
                      <p>
                          AED {item.totalPrice.toFixed(2)}
                      </p>
                      {item.discountedPrice > 0 &&
                        <p className={styles.beforeDiscount}>
                            AED {(item.totalPrice + (item.discountedPrice * item.quantity)).toFixed(2)}
                        </p>
                      }
                    </div>
                </div>
                <div>
                  <div onClick={() => handleCounterClick(item.id, item.groups)} className={`flex flex-col justify-between ${styles.counter} ${activeItem?.id === item.id && JSON.stringify(activeItem.groups) === JSON.stringify(item.groups) ? styles.counterShow : ''}`}>
                      <button
                          disabled={(activeItem || activeItem?.id !== item.id) && JSON.stringify(activeItem?.groups) !== JSON.stringify(item.groups)}
                          onClick={() => increaseQuantity(item.id, item.groups)}
                          className={`${styles.button} ${activeItem?.id === item.id && JSON.stringify(activeItem.groups) === JSON.stringify(item.groups) ? styles.buttonShow : ''}`}
                      >
                          <ReactplusIcon />
                      </button>
                      <span 
                          className={styles.count}
                          style={{ cursor: 'pointer' }}
                      >
                          {item.quantity}
                      </span>
                      <button
                          disabled={activeItem?.id === item.id && JSON.stringify(activeItem?.groups) !== JSON.stringify(item.groups)}
                          onClick={() => decreaseQuantity(item.id, item.groups)}
                          className={` ${styles.button} ${activeItem?.id === item.id && JSON.stringify(activeItem.groups) === JSON.stringify(item.groups) ? styles.buttonShow : ''}`}
                      >
                          {item.quantity > 1 ? <ReactColoredsubIcon/> : <ReacttrashIcon />}
                      </button>
                  </div>
              </div>
            </div>
            ))}
        </div>
        <div className={styles.additionalNotes}>
          <h4>Any special request?</h4>
          <div className={` flex ${styles.serviceDetail}`}>
            <input
              type="text"
              placeholder="Anything else we need to know?"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.paymentSummary}>
          <h4>Payment summary</h4>
          <div className={` ${styles.serviceDetail}`}>
            <div className={styles.amounts}>
            {orderItems &&
                orderItems.map((item: any) => (
                  <div
                    key={item.id}
                    className={` flex justify-between ${styles.subTotal}`}
                  >
                    <p className="w-3/4">
                    x{item.quantity} {item.name}
                    </p>
                    <p>AED {(item.totalPrice + (item.discountedPrice * item.quantity)).toFixed(2)}</p>
                  </div>
                ))}

                {discountedAmount > 0 &&
                  <div
                      className={` flex justify-between ${styles.subTotal} ${styles.discountAmount}`}
                    >
                      <p>
                        Discount 30%
                      </p>
                      <p>AED {discountedAmount.toFixed(2)}</p>
                    </div>
                } 
              <hr />
              <div className={` flex justify-between ${styles.subTotal}`}>
                <p>Subtotal</p>
                <p>AED {subAmount}</p>
              </div>
              <div className={` flex justify-between ${styles.deliveryFee}`}>
                  <p>Delivery fee</p>
                  <p>AED {deliveryFee.toFixed(2)}</p>
                </div>
                <hr/>
              <div className={` flex justify-between ${styles.total}`}>
                <p>Total amount</p>
                <p>AED {totalAmount}</p>
              </div>
              <div className={styles.extaData}>
              </div>
            </div>
          </div>
        </div>
        {orderItems && orderItems.length > 0 &&
        <div className={` ${styles.footer}`}>
            <button onClick={()=> onContinue(orderItems, additionalNotes)} className={`flex items-center justify-between ${styles.continue}`}>
                  <div className="flex items-center gap-2">
                    <span>{orderItems.reduce((sum:number, item:any) => sum + item.quantity, 0)}</span>
                    <p>Go to checkout</p>
                  </div>
                  <p>AED {totalAmount}</p>              

            </button>
        </div>
      }
    </div>
    </div>
  );
};

export default Basket;
